import React, { Dispatch, SetStateAction, useState } from "react";
import "./RegisterPayment.scss";
import {
  Button,
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import Flip from "@mui/icons-material/Flip";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import AttachMoney from "@mui/icons-material/AttachMoney";
import PopUp from "../PopUp/PopUp";
import registerPaymentService from "../../services/registerPaymentService";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notification.context";
import strings from "../../common/AppMessages";
interface RegisterPaymentProps {
  setRegisterPayment: Dispatch<SetStateAction<boolean>>;
  renderPreviousScan?: boolean;
  setRenderPreviousScan?: Dispatch<SetStateAction<boolean>>;
  html5QrcodeScanner?: Html5QrcodeScanner;
  decodedTicket: string;
  method?: string;
  setMethod?: Dispatch<SetStateAction<string>>;
  claimedPrize: boolean;
  setClaimedPrize: Dispatch<SetStateAction<boolean>>;
  wonAmmount: string;
}

const RegisterPayment: React.FC<RegisterPaymentProps> = ({
  setRegisterPayment,
  renderPreviousScan,
  setRenderPreviousScan,
  decodedTicket,
  method,
  setMethod,
  claimedPrize,
  setClaimedPrize,
  wonAmmount,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(500);
  const [msg, setMsg] = useState("");
  const { getError } = useNotification();

  const navigate = useNavigate();

  const handleRegisterPayment = async () => {
    setLoading(true);
    //const mock_code = mock_priceSteal ? 'error_proceso' : 'registrado';
    await registerPaymentService(decodedTicket).then((v) => {
      if (v.status !== 401 && v.status !== 429) {
        setClaimedPrize(true);
        setLoading(false);
        setType("register");
        setStatus(v.status);
        setMsg(v.data.toString());
        setOpen(true);
      } else {
        setType("errorPopup");
        if (v.status === 401) {
          setMsg(strings.general.requestNewLogin);
          setOpen(true);
        } else {
          getError(v.data, "error");
        }
      }
    });
  };

  const handleClose = async () => {
    setClaimedPrize(false);
    if (method !== undefined && setMethod !== undefined) {
      setMethod(method);
      navigate("/scan");
      if (
        renderPreviousScan !== undefined &&
        setRenderPreviousScan !== undefined
      ) {
        setRenderPreviousScan(!renderPreviousScan);
      }
      setRegisterPayment?.(false);
    } else {
      navigate("/scan");
      if (
        renderPreviousScan !== undefined &&
        setRenderPreviousScan !== undefined
      ) {
        setRenderPreviousScan(!renderPreviousScan);
      }
      setRegisterPayment?.(false);
    }
  };

  const scanAgain = async () => {
    if (claimedPrize) {
      setClaimedPrize(false);
      handleClose();
    } else {
      setType("back");
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: "-128px", marginLeft: "6px" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item sx={{ width: "555px", height: "370px", padding: "24px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckCircleOutline
              sx={{
                width: "52px",
                height: "52px",
                mt: "-16px",
                mb: "24px",
                color: "#458C48",
              }}
            />
            <Typography
              sx={{
                mb: "24px",
                color: "#444444",
                fontWeight: "medium",
                fontSize: "18px",
              }}
            >
              El boleto tiene premio. Monto del premio:
            </Typography>
            <Typography
              sx={{
                mb: "32px",
                textAlign: "center",
                background: "#EDF7ED",
                border: "1px solid #458C48",
                borderRadius: "4px",
                padding: "8px",
                fontSize: "20px",
                fontWeight: "800",
              }}
            >
              {parseInt(wonAmmount, 10).toLocaleString()} Gs
            </Typography>
            <Typography
              sx={{ textAlign: "center", fontWeight: "500", mb: "24px" }}
            >
              ¿Desea registrar el pago?
            </Typography>
            <div className="buttonContainer">
              <Button
                variant="contained"
                className="registerButton"
                startIcon={
                  <>
                    <AttachMoney />
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#FF0085",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </>
                }
                sx={{ mr: "10px", width: "200px" }}
                onClick={handleRegisterPayment}
                disabled={loading || claimedPrize}
              >
                Registrar el pago
              </Button>
              <Button
                variant="outlined"
                startIcon={<Flip />}
                sx={{ width: "200px" }}
                onClick={scanAgain}
                disabled={loading}
              >
                Escanear otro boleto
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <PopUp
        openPopup={open}
        setOpenPopup={setOpen}
        setRegisterPayment={setRegisterPayment}
        typeStatus={type}
        method={method}
        setMethod={setMethod}
        status={status}
        msg={msg}
        renderPreviousScan={renderPreviousScan}
        setRenderPreviousScan={setRenderPreviousScan}
      />
    </Container>
  );
};

export default RegisterPayment;
