const strings = {

    general:{
        criticalError: "Se ha producido un error interno. Por favor, contacte con el equipo técnico.",
        tooManyRequests: "Ha superado la cantidad de intentos. Reinténtelo mas tarde",
        internalCritialError: "Error interno del sistema. Por favor, contacte con el equipo técnico",
        emailError: "Se ha producido un error al enviar el correo. Si este error persiste, contacte con el equipo técnico.",
        sessionExpired: "La sesión ha expirado",
        unauthorized: "No autorizado",
        badRequestOfUserData: "Error al introducir los datos del usuario. Por favor, revise la información introducida.",
        badRequestOfGeneralData: "Error al introducir los datos. Por favor, revise la información introducida.",
        requestNewPasswordReset: "El enlace ha expirado. Por favor, solicite un nuevo cambio de contraseña.",
        requestNewLogin: "La sesión ha expirado. Por favor, vuelva a iniciar sesión."
    },
    oauth:{
        credentialsFail: "Credenciales no válidas",
        usernameFail: "No se ha encontrado el usuario introducido. Por favor, vuelva a cargar los datos o contacte con el equipo técnico.",
        refreshTokenFail: "No se ha podido realizar la acción. Por favor, vuelva a iniciar sesión.", 
    },
    passwordRecovery:{
        userNotFound: "No se ha encontrado el usuario introducido. Por favor, vuelva a cargar los datos o contacte con el equipo técnico.",
        sessionExpired: "El enlace no es válido o ha expirado. Por favor, solicite un nuevo cambio de contraseña."
    },
    tickets:{
        successful: "Pago de Premio Registrado",
        notFound: "Premio no encontrado.",
        alreadyPaid: "Premio ya pagado.",
        codeLenghtError: "El código introducido no es válido"
    },
    outlets:{
        creationSuccess: "El punto de venta ha sido añadido con éxito.",
        updateSuccess: "El punto de venta ha sido editado con éxito.",
        badRequest: "Error al introducir los datos. Por favor, revise la información introducida.",
        notFound: "No se ha encontrado el punto de venta.",
        notMatchingSlug: "Los slug de usuario y punto de venta no coinciden.",
        dupicatedSlug: "El slug del punto de venta ya existe ",
        duplicatedName: "El nombre del punto de venta ya existe ",
        duplicatedUserCode: "El código de usuario ya existe ",
        emailError: "Se ha producido un error al enviar el correo. Si este error persiste, contacte con el equipo técnico.",
    },
    users:{
        creationSuccess: "Usuario creado con éxito.",
        updateSuccess: "Datos de usuario actualizados con éxito.",
        badRequest: "Error al introducir los datos del usuario. Por favor, revise la información introducida.",
        notFound: "No se ha encontrado el usuario introducido. Por favor, vuelva a cargar los datos o contacte con el equipo técnico.",
        roleNotFound: "Usuario no encontrado.",
        outletNotFound: "Punto de venta no encontrado ",
        notMatchingSlug: "Los slug de usuario y punto de venta no coinciden.",
        duplicatedUserCode: "El código de usuario ya existe ",
        outletDisabled: "El punto de venta está deshabilitado ",
        emailError: "Se ha producido un error al enviar el correo. Si este error persiste, contacte con el equipo técnico.",
    }

    
}
export default strings