/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import "./SalesOutletForm.scss";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNotification } from "../../context/notification.context";
//import { newSalesOutlet, editSalesOutlet } from '../../services/salesOutletsService';
import {
  EditSalesOutletsValidation,
  NewSalesOutletsValidation,
} from "../../utils/salesOutletsValidation";
import salesOutletsService from "../../services/salesOutletsService";
import strings from "../../common/AppMessages";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";

interface ISalesOutletsData {
  id: string;
  name: string;
  phone: string;
  location: string;
  email: string;
  username: string;
  password: string;
  status: boolean;
  data?: string;
  slug: string;
}

interface SalesOutletFormProps {
  openSalesOutletForm: boolean;
  setOpenSalesOutletForm: Dispatch<SetStateAction<boolean>>;
  salesOutlets: ISalesOutletsData[];
  setSalesOutlets: Dispatch<SetStateAction<ISalesOutletsData[]>>;
  onEdit?: ISalesOutletsData | undefined;
  getAllSalesOutletsData: () => void;
  refreshOutlets: boolean;
  setRefreshOutlets: Dispatch<SetStateAction<boolean>>;
}

const SalesOutletForm: React.FC<SalesOutletFormProps> = ({
  openSalesOutletForm,
  setOpenSalesOutletForm,
  salesOutlets,
  setSalesOutlets,
  onEdit,
  refreshOutlets,
  setRefreshOutlets,
}) => {
  const { getError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [saleOutletsData, setSaleOutletsData] = useState<ISalesOutletsData>({
    id: "",
    name: "",
    phone: "",
    location: "",
    email: "",
    username: "",
    password: "",
    status: true,
    slug: "",
  });
  const [helperTextEmail, setHelperTextEmail] = useState(false);
  const [helperTextPass, setHelperTextPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  interface IONCEUser {
    id: number;
    username: string;
    name: string;
    surnames: string;
    email: string;
    status: boolean;
    salesOutlets: number;
    salesPoint?: { slug: string };
    idSalesPoint?: number;
    password: string;
    slug: string;
  }

  useEffect(() => {
    if (onEdit !== undefined) {
      setSaleOutletsData(onEdit);
    } else {
      setSaleOutletsData({
        id: `${salesOutlets ? salesOutlets.length + 1 : 1}`,
        name: "",
        phone: "",
        location: "",
        email: "",
        username: "",
        password: handleGeneratePassword(),
        status: true,
        slug: "",
      });
    }
  }, [salesOutlets, onEdit]);

  const dataSalesOutletsForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "name") {
      if (
        salesOutlets.some(
          (outlet) =>
            outlet.id !== saleOutletsData.id &&
            convertToSlug(outlet.name) === convertToSlug(e.target.value)
        )
      ) {
        getError("El nombre ya existe en otro punto de venta", "error");
        setSaleOutletsData({ ...saleOutletsData, [e.target.name]: "" });
      } else {
        setSaleOutletsData({
          ...saleOutletsData,
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "username") {
      const compoundUserRegex = /^[a-zA-Z0-9]*$/;
      const validatedUsername = compoundUserRegex.test(e.target.value)
        ? e.target.value
        : saleOutletsData.username;
      setSaleOutletsData({
        ...saleOutletsData,
        [e.target.name]: validatedUsername,
      });
    } else {
      setSaleOutletsData({
        ...saleOutletsData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // const saveToLocalStorage = async (data: any) => {
  //   // const token = jwt.sign(data, "secreto")
  //   // localStorage.setItem('token_bien_perruno', JSON.stringify(token))

  //     localStorage.setItem('userData', JSON.stringify(users));
  //     getError(editingUser ? 'Datos de usuario actualizados con éxito' : 'Usuario creado con éxito', 'success');
  //     setEditingUser(undefined);
  //     setRefreshList(!refreshList);
  //     handleClose();
  //   } catch (e: any) {
  //     getError(e.message, 'error');
  //   }
  // };

  function handleGeneratePassword() {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";

    //Se añade una mayúscula, minúscula y número aleatorios para cumplir el mínimo
    password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
    password += getRandomChar("abcdefghijklmnopqrstuvwxyz");
    password += getRandomChar("0123456789");

    //Se rellena el resto de la contra
    while (password.length < 16) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  }

  function getRandomChar(character: string) {
    const randomIndex = Math.floor(Math.random() * character.length);
    return character[randomIndex];
  }

  const checkEmptyProperties = (data: ISalesOutletsData): string => {
    const emptyProperties: string[] = [];

    // const translate = (key: string): string => {
    //   switch (key) {
    //     case 'name':
    //       return 'Nombre';
    //     case 'location':
    //       return 'Dirección';
    //     case 'username':
    //       return 'Usuario';
    //     case 'phone':
    //       return 'Teléfono';
    //     case 'email':
    //       return 'Correo';
    //     default:
    //       return '';
    //   }
    // };

    Object.entries(data).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value === "") ||
        value === undefined ||
        value === null
      ) {
        emptyProperties.push(key);
      }
    });

    return emptyProperties.join(", ");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      if (onEdit === undefined) {
        saleOutletsData.slug = convertToSlug(saleOutletsData.name);
        const missingData = checkEmptyProperties(saleOutletsData);
        if (missingData.length > 0) {
          getError("Debe rellenar todos los campos requeridos", "error");
        } else {
          await NewSalesOutletsValidation.validate(saleOutletsData);
          setLoading(true);

          const salesOutletsToCreate = {
            name: saleOutletsData.name,
            slug: convertToSlug(saleOutletsData.name),
            location: saleOutletsData.location,
            email: saleOutletsData.email,
            phone: saleOutletsData.phone,
            status: saleOutletsData.status,
            username:
              convertToSlug(saleOutletsData.name) +
              "." +
              saleOutletsData.username,
            password: saleOutletsData.password,
          };
          await salesOutletsService
            .createSalesOutletData(salesOutletsToCreate)
            .then((v) => {
              if (v.status === 1) {
                // Añadir outlet list en local
                setSalesOutlets([...salesOutlets, saleOutletsData]);
                localStorage.setItem(
                  "outletsData",
                  JSON.stringify([...salesOutlets, saleOutletsData])
                );

                // Añdir admin user en local
                const storedDataString = localStorage.getItem("userData");
                const userData: IONCEUser[] =
                  storedDataString !== null ? JSON.parse(storedDataString) : [];
                const newUserFromOutlet: IONCEUser = {
                  name: saleOutletsData.username,
                  surnames: saleOutletsData.slug,
                  email: saleOutletsData.email,
                  status: true,
                  username:
                    saleOutletsData.slug + "." + saleOutletsData.username,
                  salesOutlets: v.salesPoint ? Number(v.salesPoint[0].id) : -1,
                  idSalesPoint: v.salesPoint ? Number(v.salesPoint[0].id) : -1,
                  password: saleOutletsData.password,
                  id: userData[userData.length - 1].id + 1,
                  slug: convertToSlug(saleOutletsData.name),
                  salesPoint: { slug: convertToSlug(saleOutletsData.name) },
                };
                userData.push(newUserFromOutlet);
                localStorage.setItem("userData", JSON.stringify(userData));
                setRefreshOutlets(!refreshOutlets);
                setLoading(false);
                getError(strings.outlets.creationSuccess, "success");

                setOpenSalesOutletForm(false);
              } else {
                setLoading(false);
                if (v.status === 401) {
                  localStorage.clear();
                  navigate("/");
                } else {
                  getError(v.msg, "error");
                }
                setOpenSalesOutletForm(true);
              }
            });
        }
      } else {
        await EditSalesOutletsValidation.validate(saleOutletsData);
        setLoading(true);

        const index = salesOutlets.findIndex(
          (outlet) => outlet.id === onEdit.id
        );

        if (index !== -1) {
          const salesOutletsToUpdate = {
            id: saleOutletsData.id,
            name: saleOutletsData.name,
            location: saleOutletsData.location,
            email: saleOutletsData.email,
            phone: saleOutletsData.phone,
            status: saleOutletsData.status,
          };
          await salesOutletsService
            .updateSalesOutletData(salesOutletsToUpdate)
            .then((v) => {
              if (v.status === 1) {
                const updatedOutlets = [...salesOutlets];
                updatedOutlets[index] = saleOutletsData;
                setSalesOutlets(updatedOutlets);
                localStorage.setItem(
                  "outletsData",
                  JSON.stringify(updatedOutlets)
                );
                setRefreshOutlets(!refreshOutlets);
                getError(strings.outlets.updateSuccess, "success");
                setLoading(false);
                setOpenSalesOutletForm(false);
              } else {
                setLoading(false);
                if (v.status === 401) {
                  localStorage.clear();
                  navigate("/");
                } else {
                  getError(v.msg, "error");
                }
                setOpenSalesOutletForm(true);
              }
            });
        }
      }
    } catch (e: any) {
      getError(e.message, "error");
    }
  };

  const handleClose = () => {
    setOpenSalesOutletForm(false);
  };

  const convertToSlug = (text: string): string => {
    const slug = text.toLowerCase().replace(/\s/g, "");

    const normalizedSlug = slug
      .replace(/[áàäâ]/g, "a")
      .replace(/[éèëê]/g, "e")
      .replace(/[íìïî]/g, "i")
      .replace(/[óòöô]/g, "o")
      .replace(/[úùüû]/g, "u")
      .replace(/ñ/g, "n");

    const cleanSlug = normalizedSlug.replace(/[^a-z0-9\s-]/g, "");

    return cleanSlug;
  };

  return (
    <Dialog
      open={openSalesOutletForm}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <>
          {onEdit === undefined ? (
            <Typography
              sx={{
                mb: "32px",
                color: "#444444",
                fontWeight: "medium",
                fontSize: "20px",
              }}
            >
              Crear punto de venta
            </Typography>
          ) : (
            <Typography
              sx={{
                mb: "32px",
                color: "#444444",
                fontWeight: "medium",
                fontSize: "20px",
              }}
            >
              Editar Punto de venta
            </Typography>
          )}
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Nombre *"
              sx={{ mb: "24px" }}
              defaultValue={onEdit?.name ?? ""}
              onChange={dataSalesOutletsForm}
              disabled={loading}
              InputProps={{
                inputProps: { maxLength: 40 },
              }}
              inputProps={{
                maxLength: 40,
              }}
            />
            {onEdit !== undefined && (
              <TextField
                fullWidth
                type="text"
                name="slug"
                label="Slug *"
                sx={{ mb: "24px" }}
                defaultValue={onEdit?.slug ?? ""}
                onChange={dataSalesOutletsForm}
                disabled={onEdit !== undefined}
                inputProps={{
                  maxLength: 50,
                }}
              />
            )}

            <TextField
              fullWidth
              type="text"
              name="location"
              label="Dirección *"
              sx={{ mb: "24px" }}
              defaultValue={onEdit?.location ?? ""}
              onChange={dataSalesOutletsForm}
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="phone"
              label="Teléfono *"
              sx={{ mb: "24px" }}
              defaultValue={onEdit?.phone ?? ""}
              onChange={dataSalesOutletsForm}
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="email"
              label="Email *"
              sx={{ mb: "24px" }}
              defaultValue={onEdit?.email ?? ""}
              onChange={dataSalesOutletsForm}
              helperText={
                helperTextEmail &&
                "El correo debe tener el siguiente formato: usuario@proveedor.com "
              }
              onFocus={() => setHelperTextEmail(true)}
              onBlur={() => setHelperTextEmail(false)}
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="username"
              label="Usuario *"
              sx={{
                mb: "24px",
                display: onEdit === undefined ? "flex" : "none",
              }}
              value={saleOutletsData.username}
              onChange={dataSalesOutletsForm}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginRight: "0px" }}
                  >
                    {convertToSlug(
                      onEdit === undefined ? saleOutletsData.name : onEdit.name
                    )}
                    .
                  </InputAdornment>
                ),
              }}
              disabled={loading}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              fullWidth
              type="password"
              name="password"
              label="Contraseña *"
              defaultValue={saleOutletsData.password}
              helperText={
                helperTextPass &&
                "La contraseña debe contener al menos 8 caracteres, una letra mayúscula y un carácter numérico."
              }
              onFocus={() => setHelperTextPass(true)}
              onBlur={() => setHelperTextPass(false)}
              sx={{
                mb: "24px",
                display: onEdit === undefined ? "flex" : "none",
              }}
              onChange={dataSalesOutletsForm}
              disabled={loading}
              inputProps={{
                maxLength: 250,
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    tabIndex={-1}
                    defaultValue={"true"}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
            />
            {onEdit === undefined ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                startIcon={
                  <>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#FF0085",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </>
                }
                color="primary"
                sx={{ mb: "9px" }}
                disabled={loading}
              >
                Añadir
              </Button>
            ) : (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                startIcon={
                  <>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#FF0085",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </>
                }
                color="primary"
                sx={{ mb: "9px" }}
                disabled={loading}
              >
                Guardar
              </Button>
            )}
          </Box>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default SalesOutletForm;
