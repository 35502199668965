import React, { useEffect, useState } from "react";
import { Button, Container, Grid, IconButton, Typography } from "@mui/material";
import Flip from "@mui/icons-material/Flip";
import CameraAlt from "@mui/icons-material/CameraAlt";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import "./ScanningMethod.scss";
import Html5CodeScanner from "../Html5CodeScanner/Html5CodeScanner";
import LectorScanner from "../LectorScanner/LectorScanner";
import PopUp from "../PopUp/PopUp";
import Unauthorized from "./../../pages/Unauthorized";

interface ScanningMethodProps {
  mobileScanner: boolean;
  screenSize: {
    width: number;
    height: number;
  };
}

const ScanningMethod: React.FC<ScanningMethodProps> = ({
  mobileScanner,
  screenSize,
}) => {
  const scanningMethod = localStorage.getItem("scanningMethod");
  const [method, setMethod] = useState(scanningMethod ?? "");
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("");
  const [registerPayment, setRegisterPayment] = useState(false);
  const [claimedPrize, setClaimedPrize] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (method.split(".")[0] === "return") {
      setMethod(method.split(".")[1]);
    }
    if (method === "") {
      setRegisterPayment(false);
    }
    localStorage.setItem("scanningMethod", method);
  }, [method]);

  return (
    <>
      {role === "user" ? (
        <Container maxWidth="xl">
          {method === "" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "-2px", marginLeft: "7px", minHeight: "100vh" }}
            >
              <Grid sx={{ width: "555px", height: "370px", padding: "24px" }}>
                <Typography
                  sx={{
                    mt: 1,
                    marginBottom: "40px",
                    color: "#444444",
                    fontWeight: "medium",
                    fontSize: "18px",
                  }}
                >
                  Seleccione método de escaneo:
                </Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    className="scanOptions"
                    variant="outlined"
                    color="secondary"
                    startIcon={<Flip />}
                    sx={{ marginBottom: "24px" }}
                    onClick={() => {
                      setMethod("lector");
                      console.log("SanninMethod 40" + "lector");
                    }}
                  >
                    Lector
                  </Button>
                  <Button
                    className="scanOptions"
                    variant="outlined"
                    color="secondary"
                    startIcon={<CameraAlt />}
                    onClick={() => {
                      setMethod("camera");
                      window.location.reload();
                    }}
                  >
                    Cámara
                  </Button>
                </div>
              </Grid>
            </Grid>
          ) : method === "lector" || method === "camera" ? (
            <>
              {mobileScanner ? (
                <IconButton
                  onClick={() => {
                    setMethod("");
                  }}
                  sx={{ marginTop: mobileScanner ? "80px" : "90px" }}
                >
                  <ArrowBackIos />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ArrowBackIos />}
                  onClick={() => {
                    setMethod("");
                  }}
                  sx={{ marginTop: mobileScanner ? "80px" : "90px" }}
                >
                  Volver
                </Button>
              )}
              {method === "lector" ? (
                <LectorScanner
                  setMethod={setMethod}
                  registerPayment={registerPayment}
                  setRegisterPayment={setRegisterPayment}
                  claimedPrize={claimedPrize}
                  setClaimedPrize={setClaimedPrize}
                />
              ) : (
                <Html5CodeScanner
                  mobileScanner={mobileScanner}
                  screenSize={screenSize}
                  setMethod={setMethod}
                  registerPayment={registerPayment}
                  setRegisterPayment={setRegisterPayment}
                  claimedPrize={claimedPrize}
                  setClaimedPrize={setClaimedPrize}
                />
              )}
            </>
          ) : (
            <>
              {mobileScanner ? (
                <IconButton
                  onClick={() => {
                    if (claimedPrize) {
                      setMethod("");
                      setClaimedPrize(false);
                    } else {
                      setType("back");
                      setOpen(true);
                    }
                  }}
                  sx={{ marginTop: mobileScanner ? "80px" : "90px" }}
                >
                  <ArrowBackIos />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ArrowBackIos />}
                  onClick={() => {
                    if (claimedPrize) {
                      setMethod("");
                      setClaimedPrize(false);
                    } else {
                      setType("back");
                      setOpen(true);
                    }
                  }}
                  sx={{ marginTop: mobileScanner ? "80px" : "90px" }}
                >
                  Volver
                </Button>
              )}
              {method.split(".")[0] === "lector" ? (
                <LectorScanner
                  method={method}
                  setMethod={setMethod}
                  registerPayment={registerPayment}
                  setRegisterPayment={setRegisterPayment}
                  claimedPrize={claimedPrize}
                  setClaimedPrize={setClaimedPrize}
                />
              ) : (
                <Html5CodeScanner
                  mobileScanner={mobileScanner}
                  screenSize={screenSize}
                  method={method}
                  setMethod={setMethod}
                  registerPayment={registerPayment}
                  setRegisterPayment={setRegisterPayment}
                  claimedPrize={claimedPrize}
                  setClaimedPrize={setClaimedPrize}
                />
              )}
            </>
          )}

          <PopUp
            openPopup={open}
            setOpenPopup={setOpen}
            typeStatus={type}
            method={"return." + method.split(".")[0]}
            setMethod={setMethod}
            setRegisterPayment={setRegisterPayment}
            data={{ claimedPrize: claimedPrize }}
          />
        </Container>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default ScanningMethod;
