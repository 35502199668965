import strings from "../common/AppMessages";
import authService from '../services/authService';
const registerPaymentService = (code: string): Promise<{ status: number; data: string, winner?: boolean }> => {
    return new Promise(async (resolve) => {
        try{
            
                let accessToken = localStorage.getItem('token');

        if (localStorage.getItem('role') !== 'user' || localStorage.getItem('token') === '' || localStorage.getItem('token') === null
              || localStorage.getItem('tokenTime') === '' || localStorage.getItem('tokenTime') === null
          ) {
              resolve({ status: 401, data: strings.general.sessionExpired, winner: false })
          }

          let accessTokenTime = localStorage.getItem('tokenTime');
          let accessRefreshTokenTime = localStorage.getItem('refreshTokenTime');
          if (accessTokenTime !== null && accessRefreshTokenTime !== null) {
              const now = new Date().toISOString()
              if (now > accessTokenTime) {
                  //El token ha expirado

                  if (now > accessRefreshTokenTime) {
                      //El token de refresco ha expirado
                      resolve({ status: 401, data: strings.general.sessionExpired, winner: false })
                  }
                  else {
                      let accessRefreshToken = localStorage.getItem('refreshToken');
                      accessRefreshToken && authService.refreshToken(accessRefreshToken).then((v) =>{
                        console.log(v.token)
                        accessToken = v.token
                      })
                  }
              }
          } else {
              resolve({ status: 401, data: strings.general.sessionExpired, winner: false })
          }
        


        const response = await fetch(`/api/ticket/register`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code }),
        });

        if (response.status === 401) {
            const error = { code: "401", msg: strings.general.tooManyRequests }
            throw new Error(error.code);
        }
        if (response.status === 429) {
            const error = { code: "429", msg: strings.general.tooManyRequests }
            throw new Error(error.code);
        }
        if (response.status === null) {
            const error = { code: "500", msg: strings.general.internalCritialError }
            throw new Error(error.code);
        }

        const data = await response.json();

        if (data.status === 20) {
            resolve({ status: 400, data: strings.general.badRequestOfGeneralData, winner: false })
        }
        
        if (data.status === 1) {
            resolve({ status: 200, data: strings.tickets.successful, winner: true })
        } else {
            if (data.status === 43) {
                resolve({ status: 404, data: strings.tickets.notFound, winner: false })
            } else {
                resolve({ status: 404, data: strings.tickets.alreadyPaid, winner: false })
            }
        }
            
        }
        catch (error:any) {
            switch(error.toString()){
                case 'Error: 401':
                    resolve({  status: 401, data: strings.general.unauthorized, winner: false });
                    break;
                case 'Error: 429':
                    resolve({  status: 429, data: strings.general.tooManyRequests, winner: false });
                    break;
                default:
                    resolve({  status: 500, data: strings.general.internalCritialError, winner: false });
            }
        }
    });
}

export default registerPaymentService