/* eslint-disable @typescript-eslint/no-unused-vars */
import strings from "../common/AppMessages"
import authService from "./authService";

const salesOutletsService = {
    async getSalesOutletsData(): Promise<{ status: number; msg: string; salesPoint?: any[] }> {
        return new Promise(async (resolve) => {
            try {
                let accessToken = localStorage.getItem('token');
                authService.checkToken("admin").then((response) => {
                    if(response.status !== 1){
                        resolve({ status: response.status, msg: response.data})
                    }
                })
            const response = await fetch('/api/site', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            resolve({ status: data.status, msg: data.msg, salesPoint: data.salesPoint ? data.salesPoint : []})
        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },

    async createSalesOutletData(salesOutletData: any): Promise<{ status: number; msg: string; salesPoint?: any[] }> {
        return new Promise(async (resolve) => {
            try {
                let accessToken = localStorage.getItem('token');
            const response = await fetch('/api/site', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(salesOutletData),
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized);
            data?.status === 99 && (data.msg = strings.general.criticalError);

            data?.status === 20 && (data.msg = strings.outlets.badRequest);
            data?.status === 50 && (data.msg = strings.outlets.notMatchingSlug);
            data?.status === 51 && (data.msg = strings.outlets.dupicatedSlug);
            data?.status === 52 && (data.msg = strings.outlets.duplicatedName);
            data?.status === 53 && (data.msg = strings.outlets.duplicatedUserCode);
            data?.status === 60 && (data.msg = strings.outlets.emailError);

            resolve({ status: data.status, msg: data.msg, salesPoint: data.salesPoint ? data.salesPoint : []})
        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },

    async updateSalesOutletData(salesOutletData: any): Promise<{ status: number; msg: string; data?: any[] }> {
        return new Promise(async (resolve) => {
            try {
                let accessToken = localStorage.getItem('token');
            const response = await fetch('/api/site', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(salesOutletData),
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized);
            data?.status === 99 && (data.msg = strings.general.criticalError);

            data?.status === 20 && (data.msg = strings.outlets.badRequest);
            data?.status === 42 && (data.msg = strings.outlets.notFound);
            data?.status === 52 && (data.msg = strings.outlets.duplicatedName);

            resolve({ status: data.status, msg: data.msg, data: data.data ? data.data : []})
        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },
}

export default salesOutletsService;