import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Search from "@mui/icons-material/Search";
import "./LectorScanner.scss";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import PopUp from "../PopUp/PopUp";
import RegisterPayment from "../RegisterPayment/RegisterPayment";
import consultService from "../../services/consultService";
import { useNotification } from "../../context/notification.context";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Unauthorized from "./../../pages/Unauthorized";

import strings from "../../common/AppMessages";
interface LectorScannerProps {
  method?: string;
  setMethod: Dispatch<SetStateAction<string>>;
  registerPayment: boolean;
  setRegisterPayment: Dispatch<SetStateAction<boolean>>;
  claimedPrize: boolean;
  setClaimedPrize: Dispatch<SetStateAction<boolean>>;
}

const LectorScanner: React.FC<LectorScannerProps> = ({
  method,
  setMethod,
  registerPayment,
  setRegisterPayment,
  claimedPrize,
  setClaimedPrize,
}) => {
  const [open, setOpen] = React.useState(false);
  const [result, setResult] = useState("");
  const [checkDigits, setCheckDigits] = useState("");
  const [loading, setLoading] = useState(false);
  const [is27Digits, setIs27Digits] = useState(false);
  const [is23Digits, setIs23Digits] = useState(false);
  const [canConsult, setCanConsult] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(500);
  const [msg, setMsg] = useState("");
  const input27Ref = useRef<HTMLInputElement | null>(null);
  const input23Ref = useRef<HTMLInputElement | null>(null);
  const input23RefControlDigits = useRef<HTMLInputElement | null>(null);
  const [wonAmmount, setWonAmmount] = useState("");
  const [readTicket, setReadTicket] = useState("");
  const role = localStorage.getItem("role");

  const { getError } = useNotification();

  useEffect(() => {
    if (is27Digits === true) {
      input27Ref?.current?.focus();
      setCanConsult(true);
    } else {
      if (is23Digits === true) {
        input23RefControlDigits?.current?.focus();
      } else {
        input23Ref?.current?.focus();
        onCheckDigits("check", "");
      }
    }
  }, [is27Digits, is23Digits]);

  useEffect(() => {
    onCheckDigits("check", "");
  }, [result]);

  useEffect(() => {
    input27Ref?.current?.focus();
  }, []);

useEffect(() => {
    input27Ref?.current?.focus();
    input23Ref?.current?.focus();
}, [open]);

    useEffect(() => {
        setResult("");
        setCheckDigits("");
    }, [registerPayment]);

  const onCheckDigits = (type: string, digits: string) => {
    const is23 = digits.length === 23;
    const is27 = digits.length === 27;
    try {
      if (type === "result") {
        setResult(digits);
        setIs23Digits(is23);
        setIs27Digits(is27);
        if (is23 || is27) {
          setReadTicket(digits);
          setCanConsult(true);
          is27 && onConsult(digits);
        } else {
          setCanConsult(false);
        }
      } else if (type === "check") {
        setCheckDigits(digits);
        if (is23Digits) {
          if (digits.length === 4) {
            setReadTicket(readTicket + digits);
            setCanConsult(true);
            onConsult(readTicket + digits);
          } else {
            setCanConsult(false);
          }
        }
      }
    } catch (e) {
      getError(strings.general.criticalError, "error");
    }
  };

  const onConsult = async (ticket: string) => {
    setLoading(true);
    //const code = getLastReadTicket();

    try {
      await consultService(ticket).then((v) => {
        if (v.status !== 401 && v.status !== 429) {
          setCheckDigits("");
          setResult("");
          setIs23Digits(false);
          setCanConsult(false);
          if (v.winner === true) {
            //localStorage.setItem('scanningMethod', 'lector.success');
            setMethod("lector.success");
            setResult("");
            setIs27Digits(false);
            setLoading(false);
            v.prize && setWonAmmount(v.prize);
            setClaimedPrize(false);
            setRegisterPayment(true);
          } else {
            setLoading(false);
            setType("check");
            setStatus(v.status);
            setMsg(v.data);
            setOpen(true);
          }
        } else {
          setType("errorPopup");
          if (v.status === 401) {
            if (v.data === strings.general.requestNewLogin) {
              //Token expirado
              setMsg(strings.general.requestNewLogin);
              setOpen(true);
            } else {
              //Token no válido
              localStorage.clear();
            }
          } else {
            getError(v.data, "error");
          }
        }
        setLoading(false);
      });
    } catch (e: any) {
      setLoading(false);
      getError(e.message, "error");
    }
  };

  const handleClearClick = () => {
    setResult("");
    setReadTicket("");
    setCanConsult(false);
  };
  return (
    <>
      {role === "user" ? (
        !registerPayment ? (
          <Container
            maxWidth="xl"
            sx={{ marginTop: "-128px", marginLeft: "6px" }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "100vh" }}
            >
              <Grid
                item
                sx={{ width: "555px", height: "370px", padding: "24px" }}
              >
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: "24px",
                      color: "#444444",
                      fontWeight: "medium",
                      fontSize: "18px",
                    }}
                  >
                    Coloque su lector sobre el código de barras del boleto y
                    escanee para iniciar la consulta.
                  </Typography>
                  <Box component="text">
                    {!is23Digits ? (
                      <TextField
                        fullWidth
                        inputRef={input27Ref}
                        type="text"
                        name="ticketCode"
                        label="Código del boleto *"
                        margin="normal"
                        value={result}
                        sx={{ marginBottom: "32px" }}
                        onChange={(e) => {
                          onCheckDigits("result", e.target.value);
                        }}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={handleClearClick}
                              edge="end"
                              tabIndex={-1}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          inputRef={input23Ref}
                          type="text"
                          name="ticketCode"
                          label="Código del boleto *"
                          margin="normal"
                          value={result}
                          // sx={{ marginBottom: '24px' }}
                          onChange={(e) => {
                            onCheckDigits("result", e.target.value);
                          }}
                          disabled={loading}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={handleClearClick}
                                edge="end"
                                tabIndex={-1}
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        />
                        <TextField
                          fullWidth
                          type="text"
                          inputRef={input23RefControlDigits}
                          name="checkDigits"
                          label="Dígitos de control *"
                          margin="normal"
                          value={checkDigits}
                          sx={{ mt: 1.5, marginBottom: "32px" }}
                          onChange={(e) =>
                            onCheckDigits("check", e.target.value)
                          }
                          disabled={loading}
                          inputProps={{
                            maxLength: 4,
                          }}
                        />
                      </>
                    )}
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={
                        <>
                          <Search />
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "#FF0085",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </>
                      }
                      onClick={(e: any) => onConsult(e)}
                      disabled={!canConsult}
                    >
                      Consultar
                    </Button>
                  </Box>
                </>
              </Grid>
            </Grid>
            <PopUp
              openPopup={open}
              setOpenPopup={setOpen}
              typeStatus={type}
              status={status}
              msg={msg}
            />
          </Container>
        ) : (
          <RegisterPayment
            setRegisterPayment={setRegisterPayment}
            method={"return." + method}
            wonAmmount={wonAmmount}
            setMethod={setMethod}
            decodedTicket={readTicket}
            claimedPrize={claimedPrize}
            setClaimedPrize={setClaimedPrize}
          />
        )
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default LectorScanner;
