import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';

interface IThemeConfig {
  children: JSX.Element;
}

enum themePalette {
  BG = '#FAFAFA',
  PINK = '#FF0085',
  GREY = '#818182',
  YELLOW = '#FFFF00',
  ERROR_MAIN = '#f44336',
  BG_ERROR_MAIN = 'rgb(244, 67, 54)',
}

export const ThemeConfig: React.FC<IThemeConfig> = ({ children }) => {
  const theme = createTheme({
    palette: {
      background: {
        default: themePalette.BG,
      },
      primary: {
        main: themePalette.GREY,
      },
      secondary: {
        main: themePalette.PINK,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          style: {
            textTransform: 'none',
            boxShadow: 'none',
            borderRadius: '50px',
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          style: {
            borderRadius: '0.5rem',
            fontSize: '14px',
          },
        },
        styleOverrides: {
          standardError: {
            marginTop: '48px',
            // border: `1px solid ${themePalette.ERROR_MAIN}`,
            // background: themePalette.BG_ERROR_MAIN,
          },
          standardSuccess: {
            marginTop: '48px',
            // border: `1px solid ${themePalette.ERROR_MAIN}`,
            // background: themePalette.BG_ERROR_MAIN,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
