import React, { useState, useEffect } from 'react';
// import Html5CodeScanner from '../components/Html5CodeScanner/Html5CodeScanner';
import ScanningMethod from '../components/ScanningMethod/ScanningMethod';
import Unauthorized from './Unauthorized';

interface ScanViewHtml5Props {}

export const ScanViewHtml5: React.FC<ScanViewHtml5Props> = () => {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const role = localStorage.getItem('role');

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    console.log('Loading ScanView');
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className='App'>{role === 'user' ? <ScanningMethod mobileScanner={screenSize.width <= 480 ? true : false} screenSize={screenSize} /> : <Unauthorized />}</div>;
};
