import * as yup from 'yup';

export const LoginValidation = yup.object().shape({
  username: yup.string().trim().required('Usuario requerido'),
  password: yup.string().trim().required('Contraseña requerida'),
  loginDataInputted: yup.string().trim().required('Usuario y Contraseña requeridos'),
});

export const LoginPasswordRecoveryValidation = yup.object().shape({
  usernameRecovery: yup.string().trim().required('Usuario requerido'),
});

export const PasswordRecoveryValidation = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required('Contraseña requerida')
    .test('passwords-match', 'Las contraseñas deben coincidir', function (value) {
      return value === this.parent.confirmNewPassword;
    })
    .test('passwords-structure', 'Las contraseñas deben contener una mayúscula, una minúscula y un número', function (value) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
      return regex.test(value);
    })
    .test('passwords-lenght', 'Las contraseñas deben ser de al menos 8 caracteres', function (value) {
      const regex = /.{8,}/;
      return regex.test(value);
    }),
  confirmNewPassword: yup
    .string()
    .trim()
    .required('Confirmar nueva contraseña requerida')
    .test('passwords-match', 'Las contraseñas deben coincidir', function (value) {
      return value === this.parent.newPassword;
    }),
});
