/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, TextField, Typography, CircularProgress } from '@mui/material';
import { useNotification } from '../context/notification.context';
import { PasswordRecoveryValidation } from '../utils/loginValidation';
import authService from '../services/authService';
import PopUp from '../components/PopUp/PopUp';

interface PasswordRecoveryProps {}

interface IPasswordData {
  newPassword: string;
  confirmNewPassword: string;
}

export const PasswordRecovery: React.FC<PasswordRecoveryProps> = () => {
  const navigate = useNavigate();
  const { getError } = useNotification();
    const [loading, setLoading] = useState(false);
    const [tokenValidated, setTokenValidated] = useState(false);
    //const [tokenExpired, setTokenExpired] = useState(false);
    //const [tokenError, setTokenError] = useState(false);
  const [passwordData, setPasswordData] = useState<IPasswordData>({
    newPassword: '',
    confirmNewPassword: '',
  });
    const [helperText, setHelperText] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("")

  const dataPasswordRecovery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
        await PasswordRecoveryValidation.validate(passwordData);
        const currentUrl = window.location.href;
        const token = currentUrl.split("token=")[1]
        await authService.refreshPassword(passwordData.newPassword, token ? token : "").then((v) => {
            if (v?.status === 1) {
                getError('Su contraseña ha sido modificada', 'success');
                setTokenValidated(false)
        } else {
            setOpen(true)
            setMsg(v.msg)
            }
        })
      //setTimeout(async () => {}, 2000);

      
      navigate('/login');
      setLoading(false);
    } catch (e: any) {
      getError(e.message, 'error');
      setLoading(false);
    }
    };

    const checkIfTokenValidated = async () => {
        const currentUrl = window.location.href;
        //const urlParams = new URLSearchParams(currentUrl);
        const token = currentUrl.split("token=")[1]
        await authService.validateToken(token ? token : "").then((v) => {
            if (v?.status === 1) {
                setTokenValidated(true)
            } else {
                setOpen(true)
                setMsg(v.msg)
            }
                
            //v.status === 10 && setTokenExpired(true);

        })
    }

    useEffect(() => {
        checkIfTokenValidated()
    },[])

  return (
      <Container maxWidth='xl'>
            <Grid container direction='row' alignItems='center' justifyContent='center' sx={{ minHeight: '100vh' }}>
                <Grid className='loginCard' item sx={{ width: '555px', height: '370px', padding: '24px' }}>
                    <Typography sx={{ mb: '40px' }} variant='h5' color='secondary'>
                        Reestablecer Contraseña
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit}>
                      <TextField
                          fullWidth
                          type='password'
                          name='newPassword'
                          label='Nueva Contraseña *'
                          helperText={helperText && 'La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una minúscula y un carácter numérico.'}
                          sx={{ mb: '21px' }}
                          onChange={dataPasswordRecovery}
                          onFocus={() => setHelperText(true)}
                          onBlur={() => setHelperText(false)}
                      />
                        <TextField
                          fullWidth
                          type='password'
                          name='confirmNewPassword'
                          label='Confirmar contraseña *'
                          sx={{ marginBottom: helperText ? '35.5px' : '78px' }}
                          onChange={dataPasswordRecovery}
                          onFocus={() => setHelperText(false)}
                          disabled={!tokenValidated}

                        />
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                          color='primary'
                          disabled={!tokenValidated}
                            startIcon={
                                <>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: '#FF0085',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </>
                            }>
                            Reestablecer contraseña
                        </Button>
                    </Box>
                </Grid>
            </Grid>
          <PopUp openPopup={open} setOpenPopup={setOpen} typeStatus={'errorPopup'} msg={msg} />
    </Container>
  );
};
