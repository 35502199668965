import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
//import Search from '@mui/icons-material/Search';
import PersonAddAlt from "@mui/icons-material/PersonAddAlt";
import DownloadIcon from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import Person from "@mui/icons-material/Person";
import PersonOff from "@mui/icons-material/PersonOff";
import LockReset from "@mui/icons-material/LockReset";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import UserForm from "../UserForm/UserForm";
import PopUp from "../PopUp/PopUp";
import { useNotification } from "../../context/notification.context";
import useMediaQuery from "@mui/material/useMediaQuery";
//import usersDataFromJSON from '../../mock/users.json';
import usersService from "../../services/usersService";
import Pagination from "@mui/material/Pagination";
//import styles from './UserForm.scss';
import authService from "../../services/authService";
import strings from "../../common/AppMessages";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

interface UsersProps {
  setNumberOfUsers: Dispatch<SetStateAction<number>>;
}

interface IONCEUser {
  id: number;
  username: string;
  name: string;
  surnames: string;
  email: string;
  idSalesPoint: number;
  password: string;
  slug: string;
  status: boolean;
  salesPoint: { slug: string; nameOutlet: string };
}

export const Users: React.FC<UsersProps> = ({ setNumberOfUsers }) => {
  const [openUserForm, setOpenUserForm] = React.useState(false);
  const [users, setUsers] = useState<IONCEUser[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchedUserData, setFetchedUserData] = useState<any[]>([]);
  const [parsedUserData, setParsedUserData] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [editingUser, setEditingUser] = useState<IONCEUser>();
  const [data, setData] = useState<any>();
  const [searchUsers, setSearchUsers] = useState("");
  const { getError } = useNotification();
  const [refreshList, setRefreshList] = React.useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1);

  const isSmallScreen = useMediaQuery("(max-width: 440px)");

  const [sortingProperty, setSortingProperty] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  function createData(
    id: number,
    slug: string,
    username: string,
    name: string,
    surname: string,
    email: string,
    salesOutletName: string,
    salesOutlets: number,
    salesPoint: { slug: string; nameOutlet: string },
    status: JSX.Element,
    actions: JSX.Element
  ) {
    return {
      id,
      slug,
      username,
      name,
      surname,
      email,
      salesOutletName,
      salesOutlets,
      salesPoint,
      status,
      actions,
    };
  }

  const convertToCSV = (objArray: any) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let csv = "";

    // Agregar encabezados
    csv += "\uFEFF" + Object.keys(array[0]).join(";") + "\n"; // Agregar BOM

    // Agregar filas
    array.forEach((item: any) => {
      csv += Object.values(item).join(";") + "\n";
    });

    return csv;
  };

  const handleExport = () => {
    const userSinPassword = rows.map(({ password, ...rest }) => rest);

    const csvString = convertToCSV(userSinPassword);

    // Crear un objeto Blob que representa el archivo CSV
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Crear un enlace de descarga
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "usuarios.csv";

    // Hacer clic en el enlace para iniciar la descarga
    downloadLink.click();
  };

  const handleOnDisableUser = async (id: number) => {
    const storedDataString = localStorage.getItem("userData");
    if (storedDataString !== null) {
      const userData: IONCEUser[] = JSON.parse(storedDataString);
      const index = userData.findIndex((user: any) => {
        return user.id.toString() === id.toString();
      });
      if (index !== -1) {
        const userDataForUpdate = {
          id: userData[index].id,
          name: userData[index].name,
          email: userData[index].email,
          surnames: userData[index].surnames,
          status: userData[index].status === false ? true : false,
        };
        await usersService.updateUser(userDataForUpdate).then((v) => {
          if (v.status === 1) {
            getError(strings.users.updateSuccess, "success");
            userData[index].status =
              userData[index].status === false ? true : false;
            userData.splice(index, 1, userData[index]);
            localStorage.setItem("userData", JSON.stringify(userData));
            getAllUserData();
          } else {
            getError(v.msg, "error");
          }
        });
      }
    }
  };

  const handlePasswordChange = async (id: number) => {
    const storedDataString = localStorage.getItem("userData");
    if (storedDataString !== null) {
      const userData: IONCEUser[] = JSON.parse(storedDataString);
      const index = userData.findIndex((user: any) => {
        return user.id.toString() === id.toString();
      });
      if (index !== -1) {
        await authService
          .sendRecoveryMail(userData[index].username)
          .then((v) => {
            if (v?.status === 1) {
              getError(
                "En breves minutos recibirá un correo para restablecer su contraseña, recuerde revisar la carpeta SPAM por si fuese redirigido allí",
                "success"
              );
              setLoading(false);
            } else {
              getError(v.msg, "error");
              setLoading(false);
            }
          });
      }
    }
  };

  function sortUsersByID(users: IONCEUser[]) {
    return users.slice().sort((a, b) => {
      const idA = a.id;
      const idB = b.id;

      if (idA < idB) {
        return -1;
      }
      if (idA > idB) {
        return 1;
      }

      return 0;
    });
  }

  function sortUsersByColumn(
    users: IONCEUser[],
    column: string,
    mode: boolean
  ) {
    return users.slice().sort((a, b) => {
      let elementA = a[column];
      let elementB = b[column];
      if (column === "salesPoint") {
        elementA = elementA?.nameOutlet?.toString();
        elementB = elementB?.nameOutlet?.toString();
      }
      // mode === false => Ordena alfabéticamente / de menor a mayor
      // mode === true => Ordena alfabéticamente al revés / de mayor a menor
      if (typeof elementA === "string" && typeof elementB === "string") {
        return mode === false
          ? elementA.localeCompare(elementB)
          : elementB.localeCompare(elementA);
      } else if (typeof elementA === "number" && typeof elementB === "number") {
        return mode === false ? elementA - elementB : elementB - elementA;
      } else {
        // Handle other types as needed
        return 0;
      }
    });
  }

  function filterEnabled(status: string) {
    if (status === "null") {
      setIsFiltered(false);
      let storedDataString = localStorage.getItem("userData");
      storedDataString !== null
        ? parseUserData(storedDataString)
        : getAllUserData();
    } else {
      setIsFiltered(true);
      const filteredData = parsedUserData.filter((user) => {
        return user?.status?.props.titleAccess === status;
      });
      setRows(filteredData);
      setFetchedUserData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
    }
  }
  const parseUserData = (storedDataString: any) => {
    const userData: any[] = JSON.parse(storedDataString);
    const formattedList: any[] = [];
    setUsers(JSON.parse(storedDataString));
    userData.map((user) => {
      const salesPointData = {
        slug: user.salesPoint?.slug,
        nameOutlet: user.salesPoint?.name,
      };

      formattedList.push(
        createData(
          user.id,
          user.salesPoint?.slug,
          user.username,
          user.name,
          user.surnames,
          user.email,
          salesPointData.nameOutlet,
          user.idSalesPoint,
          salesPointData,
          user.status === true ? (
            <CheckCircle titleAccess="Habilitado" sx={{ color: "#458C48" }} />
          ) : (
            <Cancel titleAccess="Deshabilitado" sx={{ color: "#f44336" }} />
          ),
          <>
            <IconButton
              title="Editar"
              disabled={user.status === false}
              onClick={() => {
                setOpenUserForm(true);
                setEditingUser(user);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              title="Refrescar contraseña"
              disabled={user.status === false}
              onClick={() => {
                setOpen(true);
                setData({
                  userName: user.name + " " + user.surnames,
                  typeStatus: "newPassword",
                  id: user.id,
                });
              }}
            >
              <LockReset />
            </IconButton>
            <IconButton
              title="Deshabilitar usuario"
              onClick={() => {
                setOpen(true);
                setData({
                  userName: user.name + " " + user.surnames,
                  typeStatus:
                    user.status === true ? "enabledUser" : "disabledUser",
                  id: user.id,
                });
              }}
            >
              {user.status === true ? <PersonOff /> : <Person />}
            </IconButton>
          </>
        )
      );
      setParsedUserData(formattedList);
      setRows(sortUsersByID(formattedList));
      setFetchedUserData(sortUsersByID(formattedList));
      setTotalPages(Math.ceil(formattedList.length / itemsPerPage));
    });
  };

  const getAllUserData = () => {
    setLoading(true);
    let storedDataString = localStorage.getItem("userData");

    if (storedDataString === null) {
      usersService.getUserData().then((v) => {
        if (v.status === 1) {
          storedDataString = JSON.stringify(v.users);
          localStorage.setItem("userData", JSON.stringify(v.users));
          parseUserData(storedDataString);
        } else {
          if (v.status === 401) {
            localStorage.clear();
          } else {
            getError(v.msg, "error");
          }
        }
        setLoading(false);
      });
    } else {
      storedDataString && parseUserData(storedDataString);
      setLoading(false);
    }
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return rows.slice(startIndex, endIndex);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  useEffect(() => {
    getAllUserData();
    setLoading(false);
  }, []);

  const paginatedData = getPaginatedData();

  useEffect(() => {
    if (searchUsers === "") {
      setLoading(true);
      setIsFiltered(false);
      getAllUserData();
      setLoading(false);
    }
  }, [searchUsers, refreshList]);

  useEffect(() => {
    setLoading(true);
    getAllUserData();
    const storedDataString = localStorage.getItem("userData");
    const storedData =
      storedDataString !== null ? JSON.parse(storedDataString) : [];
    setNumberOfUsers(storedData.length);
    setLoading(false);
  }, [refreshList]);

  const getLabelOfOutlet = (id: number): string => {
    const listOfOutletsOptionsJSON = localStorage.getItem(
      "listOfOutletsOptions"
    );
    const salesOutlets =
      listOfOutletsOptionsJSON !== null
        ? JSON.parse(listOfOutletsOptionsJSON)
        : [];
    const vendingPoint: { value: number; label: string } = salesOutlets?.find(
      (option: { value: number; label: string }) => {
        return option.value.toString() === id?.toString();
      }
    );
    return vendingPoint ? vendingPoint.label : "";
  };

  const handleColumnSort = (internalName: string) => {
    setLoading(true);
    if (sortingProperty === internalName) {
      const sortedRows = sortUsersByColumn(rows, internalName, false);
      setRows(sortedRows);
      setFetchedUserData(sortedRows);
      setSortingProperty(internalName + "2");
    } else {
      const sortedRows = sortUsersByColumn(rows, internalName, true);
      setRows(sortedRows);
      setFetchedUserData(sortedRows);
      setSortingProperty(internalName);
    }
    setLoading(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const cropLongText = (text: string) => {
    if (text.length > 20) {
      return text.slice(0, 17) + "...";
    } else {
      return text;
    }
  };
  return (
    <>
      <Grid
        className="codeInput"
        container
        sx={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <TextField
          label="Buscar usuario"
          size={"small"}
          variant="outlined"
          sx={{ width: isSmallScreen ? "75%" : "78%" }}
          onChange={(v) => {
            const filteredUsers = fetchedUserData.filter(
              (row) =>
                row?.name
                  ?.toLowerCase()
                  .includes(v.target.value.toLowerCase()) ||
                row?.surname
                  ?.toLowerCase()
                  .includes(v.target.value.toLowerCase()) ||
                row?.salesPoint?.nameOutlet
                  ?.toLowerCase()
                  .includes(v.target.value.toLowerCase()) ||
                row?.email
                  ?.toLowerCase()
                  .includes(v.target.value.toLowerCase()) ||
                row?.username
                  ?.toLowerCase()
                  .includes(v.target.value.toLowerCase())
            );
            setRows(filteredUsers);
            setTotalPages(Math.ceil(filteredUsers.length / itemsPerPage));
            setCurrentPage(1);
            setSearchUsers(v.target.value);
          }}
        />
        <Button
          variant="contained"
          startIcon={<PersonAddAlt />}
          onClick={() => setOpenUserForm(true)}
          sx={{
            ml: "5px",
            width: "25%%",
            minWidth: !isSmallScreen ? "100px" : "0px",
            "& .MuiButton-startIcon": {
              marginLeft: isSmallScreen ? "auto" : "5px",
              marginRight: isSmallScreen ? "auto" : "5px",
            },
          }}
        >
          {!isSmallScreen && "Crear usuario"}
        </Button>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleExport}
          sx={{
            ml: "5px",
            width: "25%%",
            minWidth: !isSmallScreen ? "100px" : "0px",
            "& .MuiButton-startIcon": {
              marginLeft: isSmallScreen ? "auto" : "5px",
              marginRight: isSmallScreen ? "auto" : "5px",
            },
          }}
        >
          {!isSmallScreen && "Descargar"}
        </Button>
      </Grid>
      {loading ? (
        <>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <CircularProgress />
            </div>
          </div>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, paddingLeft: "30px" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  ID
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("id");
                    }}
                  >
                    {sortingProperty?.startsWith("id") ? (
                      sortingProperty === "id" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  {"Usuario"}
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("username");
                    }}
                  >
                    {sortingProperty?.startsWith("username") ? (
                      sortingProperty === "username" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Nombre
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("name");
                    }}
                  >
                    {sortingProperty?.startsWith("name") ? (
                      sortingProperty === "name" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Apellidos{" "}
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("surname");
                    }}
                  >
                    {sortingProperty?.startsWith("surname") ? (
                      sortingProperty === "surname" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Email{" "}
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("email");
                    }}
                  >
                    {sortingProperty?.startsWith("email") ? (
                      sortingProperty === "email" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Punto de venta{" "}
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("salesPoint");
                    }}
                  >
                    {sortingProperty?.startsWith("salesPoint") ? (
                      sortingProperty === "salesPoint" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Estado{" "}
                  <Button
                    style={{
                      minWidth: "0px",
                      color: isFiltered === false ? "#a1a1a1" : "#ff752c",
                    }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <FilterAltIcon />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => filterEnabled("Habilitado")}>
                      Habilitado
                    </MenuItem>
                    <MenuItem onClick={() => filterEnabled("Deshabilitado")}>
                      Deshabilitado
                    </MenuItem>
                    <MenuItem onClick={() => filterEnabled("null")}>
                      Borrar filtros
                    </MenuItem>
                  </Menu>
                </TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData
                .filter(
                  (row) =>
                    row.name
                      .toLowerCase()
                      .includes(searchUsers.toLowerCase()) ||
                    row.surname
                      .toLowerCase()
                      .includes(searchUsers.toLowerCase()) ||
                    row?.salesPoint?.nameOutlet
                      ?.toLowerCase()
                      .includes(searchUsers.toLowerCase()) ||
                    row.email
                      .toLowerCase()
                      .includes(searchUsers.toLowerCase()) ||
                    row?.username
                      ?.toLowerCase()
                      .includes(searchUsers.toLowerCase())
                )

                .map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      title={row.username}
                    >
                      {cropLongText(row.username)}
                    </TableCell>
                    <TableCell align="center" title={row.name}>
                      {cropLongText(row.name)}
                    </TableCell>
                    <TableCell align="center" title={row.surname}>
                      {cropLongText(row.surname)}
                    </TableCell>
                    <TableCell align="center" title={row.email}>
                      {cropLongText(row.email)}
                    </TableCell>
                    <TableCell align="center">
                      {getLabelOfOutlet(row.salesOutlets)}
                    </TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">{row.actions}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={totalPages}
            onChange={handlePageChange}
            disabled={totalPages === 0 || totalPages === 1}
            style={{
              position: "absolute",
              margin: "20px",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </TableContainer>
      )}

      <UserForm
        openUserForm={openUserForm}
        users={users}
        setOpenUserForm={setOpenUserForm}
        editingUser={editingUser}
        setEditingUser={setEditingUser}
        setLoading={setLoading}
        loading={loading}
        setRefreshList={setRefreshList}
        refreshList={refreshList}
      />
      <PopUp
        openPopup={open}
        setOpenPopup={setOpen}
        typeStatus={data ? data.typeStatus : undefined}
        data={{ userFullName: data?.userName, id: data?.id }}
        handleOnDisableUser={handleOnDisableUser}
        handlePasswordChange={handlePasswordChange}
      />
    </>
  );
};
