import * as yup from 'yup';

const listOfOutletsOptionsstring = localStorage.getItem('listOfOutletsOptions');
const listOfOutletsOptionsJSON = listOfOutletsOptionsstring !== null ? JSON.parse(listOfOutletsOptionsstring) : [];

export const NewSalesOutletsValidation = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('Contraseña requerida')
    .test('passwords-structure', 'Las contraseñas deben contener una mayúscula, una minúscula y un número', function (value) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
      return regex.test(value);
    })
    .test('passwords-length', 'Las contraseñas deben tener al menos 8 caracteres', function (value) {
      const regex = /.{8,}/;
      return regex.test(value);
    }),

  username: yup.string().trim().required('Usuario requerido'),
  email: yup.string().trim().required('Email requerido').email('Debe ser un correo válido'),
  phone: yup.number().required('Teléfono requerido').typeError('Teléfono debe ser numérico'),
  location: yup.string().trim().required('Dirección requerida'),
  name: yup
    .string()
    .trim()
    .required('Nombre requerido')
    .test('is-unique', 'El nombre ya existe', function (value) {
      // Check if the name is unique compared to listOfOutlets
      return listOfOutletsOptionsJSON.every((outlet: any) => outlet.label !== value);
    }),
});

export const EditSalesOutletsValidation = yup.object().shape({
  email: yup.string().trim().required('Email requerido').email('Debe ser un correo válido'),
  phone: yup.number().required('Teléfono requerido').typeError('Teléfono debe ser numérico'),
  location: yup.string().trim().required('Dirección requerida'),
  name: yup.string().trim().required('Nombre requerido'),
});
