/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import Search from "@mui/icons-material/Search";
import Settings from "@mui/icons-material/Settings";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import "./Html5CodeScanner.scss";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import RegisterPayment from "../RegisterPayment/RegisterPayment";
import PopUp from "../PopUp/PopUp";
import consultService from "../../services/consultService";
import { useNavigate } from "react-router-dom";
import Unauthorized from "./../../pages/Unauthorized";
import strings from "../../common/AppMessages";

interface IQrbox {
  width: number;
  height: number;
}

interface Html5CodeScannerProps {
  mobileScanner: boolean;
  screenSize?: {
    width: number;
    height: number;
  };
  method?: string;
  setMethod: Dispatch<SetStateAction<string>>;
  registerPayment: boolean;
  setRegisterPayment: Dispatch<SetStateAction<boolean>>;
  claimedPrize: boolean;
  setClaimedPrize: Dispatch<SetStateAction<boolean>>;
}

interface configScan {
  fps: number;
  qrbox?: IQrbox;
  aspectRatio?: number;
  disableFlip?: boolean;
}

const Html5CodeScanner: React.FC<Html5CodeScannerProps> = ({
  mobileScanner,
  method,
  setMethod,
  registerPayment,
  setRegisterPayment,
  claimedPrize,
  setClaimedPrize,
}) => {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [checkDigits, setCheckDigits] = useState("");
  const [loading, setLoading] = useState(false);
  const [is23Digits, setIs23Digits] = useState(false);
  const [canConsult, setCanConsult] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(500);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [wonAmmount, setWonAmmount] = useState("");
  const [decodedTicket, setDecodedTicket] = useState("");

  const role = localStorage.getItem("role");

  const [qrboxSize, setQrboxSize] = useState(
    localStorage.getItem("qrboxSize") ?? "normal"
  );
  const qrcodeRegionId = "html5qr-code-full-region";
  const input23RefControlDigits = useRef<HTMLInputElement | null>(null);

  const [renderNewScan, setRenderNewScan] = useState(false);
  const [renderPreviousScan, setRenderPreviousScan] = useState(false);

  //const [debugData, setdebugData] = useState<any>();

  const [readTicket, setReadTicket] = useState("");

  const [html5QrcodeScanner, setHtml5QrcodeScanner] =
    useState<Html5QrcodeScanner>();

  const [qrBoxConfig, setQrBoxConfig] = useState<any>();

  const videoAreaComponentRef = useRef(null);
  //let html5QrcodeScanner: Html5QrcodeScanner;

  useEffect(() => {
    setRenderNewScan(!renderNewScan);
  }, []);
  useEffect(() => {
    setResult("");
    setCheckDigits("");
    if (registerPayment === false) {
      html5QrcodeScanner && html5QrcodeScanner.resume();
      setIs23Digits(false);
    }
  }, [registerPayment]);

  useEffect(() => {
    html5QrcodeScanner &&
      html5QrcodeScanner.render(handleScanSuccess, onScanFailure);
  }, [html5QrcodeScanner]);

  useEffect(() => {
    setLoading(true);
    localStorage.removeItem("HTML5_QRCODE_DATA");
    localStorage.removeItem("qrboxConfig");

    const componentVideo: any = videoAreaComponentRef.current;
    if (componentVideo !== null) {
      const videoWidth = componentVideo.clientWidth;
      const videoHeight = componentVideo.clientHeight;

      const aspectRatio = 250 / 150; // 250 width, 150 height

      let qrboxWidth, qrboxHeight;

      if (videoWidth / videoHeight > aspectRatio) {
        // Video is wider
        qrboxWidth = videoHeight * aspectRatio;
        qrboxHeight = videoHeight;
      } else {
        // Video is taller
        qrboxWidth = 250;
        qrboxHeight = 150;
      }
      const configJSON = localStorage.getItem("qrboxConfig");
      const configNormal: configScan = configJSON
        ? JSON.parse(configJSON)
        : !mobileScanner
        ? {
            qrbox: {
              width: qrboxWidth > 150 ? qrboxWidth * 0.6 * 1 : 250,
              height: qrboxHeight > 150 ? qrboxHeight * 0.95 * 1 : 150,
            },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          }
        : {
            qrbox: { width: 203, height: 105 },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          };

      setLoading(false);
      const htmlScannerNormal = new Html5QrcodeScanner(
        qrcodeRegionId,
        configNormal,
        false
      );

      setHtml5QrcodeScanner(htmlScannerNormal);
    }
  }, [renderNewScan]);

  async function handleScanSuccess(decodedText: string) {
    setResult(decodedText);
    html5QrcodeScanner && html5QrcodeScanner.pause();
    setCheckDigits("");

    if (decodedText.length === 23) {
      input23RefControlDigits?.current?.focus();
      setIs23Digits(true);
      setReadTicket(decodedText);
    } else {
      setCanConsult(true);
      onConsult(decodedText);
    }
  }
  const onConsult = async (code: string) => {
    setCanConsult(true);

    //setLoading(true);
    await consultService(code).then((v) => {
      if (v.winner === true) {
        setDecodedTicket(code);
        setClaimedPrize(false);
        v.prize && setWonAmmount(v.prize);
        setResult("");
        setRegisterPayment(true);
        setMethod("camera.success");
        setIs23Digits(false);
      } else {
        if (v.status === 401) {
          if (v.data === strings.general.requestNewLogin) {
            //Token expirado
            setType("errorPopup");
            setMsg(strings.general.requestNewLogin);
            setOpen(true);
          } else {
            //Token no válido
            localStorage.clear();
          }
        } else {
          //setRenderNewScan(!renderNewScan);
          //setLoading(false);
          setIs23Digits(false);
          setResult("");
          setType("check");
          setStatus(v.status);
          setMsg(v.data);
          setOpen(true);
          html5QrcodeScanner && html5QrcodeScanner.resume();
        }
      }
    });
  };

  const onScanFailure = () => {
    // console.log(`Error: ${error}`);
  };

  const onCheckDigits = (digits: string) => {
    setCheckDigits(digits);
    if (digits.length === 4) {
      onConsult(readTicket + digits);
      setCanConsult(true);
    } else {
      setCanConsult(false);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorQrbox, setAnchorQrbox] = useState<HTMLButtonElement | null>(
    null
  );
  const openPopover = Boolean(anchorEl);
  const openPopoverQrbox = Boolean(anchorQrbox);
  const infoID = openPopover ? "simple-popover" : undefined;
  const qrboxID = openPopoverQrbox ? "simple-popover" : undefined;

  const handleChangeQrboxSize = () => {
    const componentVideo: any = videoAreaComponentRef.current;
    if (componentVideo !== null) {
      const videoWidth = componentVideo.clientWidth;
      const videoHeight = componentVideo.clientHeight;

      const aspectRatio = 250 / 150; // 250 width, 150 height

      let qrboxWidth, qrboxHeight;

      if (videoWidth / videoHeight > aspectRatio) {
        // Video is wider
        qrboxWidth = videoHeight * aspectRatio;
        qrboxHeight = videoHeight;
      } else {
        // Video is taller
        qrboxWidth = 250;
        qrboxHeight = 150;
      }
      //const configSizeJSON = localStorage.getItem('qrboxSize');

      //const lenseMultiplier = configSizeJSON === 'small' ? 0.9 : configSizeJSON === 'large' ? 1.2 : 1;
      const configJSON = localStorage.getItem("qrboxConfig");
      const configSmall: configScan = configJSON
        ? JSON.parse(configJSON)
        : !mobileScanner
        ? {
            qrbox: {
              width: qrboxWidth > 150 ? qrboxWidth * 0.6 * 0.9 : 250,
              height: qrboxHeight > 150 ? qrboxHeight * 0.95 * 0.9 : 150,
            },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          }
        : {
            qrbox: { width: 170, height: 80 },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          };

      const configNormal: configScan = configJSON
        ? JSON.parse(configJSON)
        : !mobileScanner
        ? {
            qrbox: {
              width: qrboxWidth > 150 ? qrboxWidth * 0.6 * 1 : 250,
              height: qrboxHeight > 150 ? qrboxHeight * 0.95 * 1 : 150,
            },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          }
        : {
            qrbox: { width: 203, height: 105 },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          };

      const configBig: configScan = configJSON
        ? JSON.parse(configJSON)
        : !mobileScanner
        ? {
            qrbox: {
              width: qrboxWidth > 150 ? qrboxWidth * 0.6 * 1.2 : 250,
              height: qrboxHeight > 150 ? qrboxHeight * 0.95 * 1.2 : 150,
            },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          }
        : {
            qrbox: { width: 240, height: 120 },
            fps: 10,
            showZoomSliderIfSupported: true,
            defaultZoomValueIfSupported: 2,
          };

      setLoading(false);

      if (localStorage.getItem("qrboxSize") === "small") {
        setQrBoxConfig(configSmall);
      } else if (localStorage.getItem("qrboxSize") === "normal") {
        setQrBoxConfig(configNormal);
      } else if (localStorage.getItem("qrboxSize") === "large") {
        setQrBoxConfig(configBig);
      }
    }
    setAnchorQrbox(null);
    navigate("/scan");
  };

  useEffect(() => {
    html5QrcodeScanner?.clear();
    qrBoxConfig &&
      setHtml5QrcodeScanner(
        new Html5QrcodeScanner(qrcodeRegionId, qrBoxConfig, false)
      );
  }, [qrBoxConfig]);

  return (
    <>
      {role === "user" ? (
        <div>
          {!registerPayment ? (
            <Container
              className="scanContainer"
              sx={{ mt: "-40px", width: "100%", height: "100%" }}
            >
              <Grid
                container
                direction={"column"}
                sx={{
                  width: "100%",
                  height: "100%",
                  pl: mobileScanner ? "0px" : "70px",
                }}
              >
                {!mobileScanner ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 1,
                        mb: "16px",
                        color: "#444444",
                        fontWeight: "medium",
                        fontSize: "18px",
                      }}
                    >
                      Coloque su cámara sobre el código del boleto y escanee
                      para consultar si está premiado.
                    </Typography>

                    <IconButton
                      sx={{ width: "36px", height: "36px" }}
                      onClick={(e) => setAnchorQrbox(e.currentTarget)}
                    >
                      <Settings />
                    </IconButton>

                    {/* {debugData &&
                        'videoWidth: ' + debugData.videoWidth + 'videoHeight: ' + debugData.videoHeight + 'qrboxWidth: ' + debugData.qrboxWidth + 'qrboxHeight: ' + debugData.qrboxHeight} */}
                    <Popover
                      id={qrboxID}
                      open={openPopoverQrbox}
                      anchorEl={anchorQrbox}
                      onClose={() => setAnchorQrbox(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <FormControl sx={{ p: 2 }}>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Zona de escaneo:
                        </FormLabel>
                        <RadioGroup
                          value={qrboxSize}
                          onChange={(_, v) => {
                            setQrboxSize(v),
                              localStorage.setItem("qrboxSize", v);
                          }}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="small"
                            control={<Radio />}
                            label="Pequeña"
                          />
                          <FormControlLabel
                            value="normal"
                            control={<Radio />}
                            label="Normal"
                          />
                          <FormControlLabel
                            value="large"
                            control={<Radio />}
                            label="Grande"
                          />
                        </RadioGroup>
                        <Button
                          sx={{ mt: 1 }}
                          variant="contained"
                          onClick={handleChangeQrboxSize}
                        >
                          Aplicar
                        </Button>
                      </FormControl>
                    </Popover>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: mobileScanner ? "0px" : "10px",
                      marginBottom: mobileScanner ? "0px" : "-8px",
                    }}
                  >
                    <IconButton
                      sx={{ width: "36px", height: "36px" }}
                      onClick={(e) => setAnchorQrbox(e.currentTarget)}
                    >
                      <Settings />
                    </IconButton>
                    <Popover
                      id={qrboxID}
                      open={openPopoverQrbox}
                      anchorEl={anchorQrbox}
                      onClose={() => setAnchorQrbox(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <FormControl sx={{ p: 2 }}>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Zona de escaneo:
                        </FormLabel>
                        <RadioGroup
                          value={qrboxSize}
                          onChange={(_, v) => {
                            {
                              setQrboxSize(v),
                                localStorage.setItem("qrboxSize", v);
                            }
                          }}
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="small"
                            control={<Radio />}
                            label="Pequeña"
                          />
                          <FormControlLabel
                            value="normal"
                            control={<Radio />}
                            label="Normal"
                          />
                          <FormControlLabel
                            value="large"
                            control={<Radio />}
                            label="Grande"
                          />
                        </RadioGroup>
                        <Button
                          sx={{ mt: 1 }}
                          variant="contained"
                          onClick={handleChangeQrboxSize}
                        >
                          Aplicar
                        </Button>
                      </FormControl>
                    </Popover>
                    <IconButton
                      sx={{ width: "36px", height: "36px" }}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <InfoOutlined />
                    </IconButton>
                    <Popover
                      id={infoID}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        Coloque su cámara sobre el código del boleto y escanee
                        para consultar si está premiado.
                      </Typography>
                    </Popover>
                  </div>
                )}
                <Grid
                  className="codeInput"
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: mobileScanner ? "20px" : "24px",
                  }}
                >
                  {!is23Digits ? (
                    result && (
                      <TextField
                        label="Código del boleto *"
                        variant="outlined"
                        value={result}
                        sx={{ width: "100%" }}
                      />
                    )
                  ) : (
                    <>
                      <TextField
                        label="Código del boleto *"
                        variant="outlined"
                        value={result}
                        sx={{
                          width: mobileScanner ? "100%" : "70%",
                          mr: "8px",
                        }}
                      />
                      <TextField
                        fullWidth
                        type="text"
                        inputRef={input23RefControlDigits}
                        autoFocus={is23Digits}
                        name="checkDigits"
                        label={mobileScanner ? "DC *" : "Dígitos de Control *"}
                        value={checkDigits}
                        sx={{ width: mobileScanner ? "50%" : "16%", mt: "6px" }}
                        onChange={(e) => onCheckDigits(e.target.value)}
                      />

                      {!mobileScanner ? (
                        <Button
                          variant="contained"
                          startIcon={
                            <>
                              <Search />
                              {loading && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "#FF0085",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                  }}
                                />
                              )}
                            </>
                          }
                          onClick={() => onConsult(result)}
                          sx={{ ml: "8px" }}
                          disabled={!canConsult}
                        >
                          Consultar
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => onConsult(result)}
                          sx={{ ml: "8px" }}
                          disabled={!canConsult}
                        >
                          <Search />
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "#FF0085",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <PopUp
                openPopup={open}
                setOpenPopup={setOpen}
                typeStatus={type}
                status={status}
                msg={msg}
              />
            </Container>
          ) : (
            <RegisterPayment
              setRegisterPayment={setRegisterPayment}
              renderPreviousScan={renderPreviousScan}
              setRenderPreviousScan={setRenderPreviousScan}
              decodedTicket={decodedTicket}
              wonAmmount={wonAmmount}
              method={"return." + method}
              setMethod={setMethod}
              claimedPrize={claimedPrize}
              setClaimedPrize={setClaimedPrize}
            />
          )}
          <Container
            className="scanContainer"
            sx={{ mt: "-10px", width: "100%", height: "100%" }}
          >
            <Grid
              container
              direction={"column"}
              sx={{
                width: "100%",
                height: "100%",
                pl: mobileScanner ? "0px" : "70px",
              }}
            >
              {
                <div
                  style={{
                    marginTop: "-1px",
                    height: "464px",
                    display: registerPayment ? "none" : "block",
                  }}
                  ref={videoAreaComponentRef}
                  id={qrcodeRegionId}
                />
              }
            </Grid>
          </Container>
        </div>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default Html5CodeScanner;
