/* eslint-disable @typescript-eslint/no-unused-vars */
import strings from "../common/AppMessages"
import authService from "./authService";

const userService = {

    async getUserData (): Promise<{ status: number, msg: string, users?: any[] }> {
        return new Promise(async (resolve) => {
        try {
            let accessToken = localStorage.getItem('token');
            authService.checkToken("admin").then((response) => {
                if(response.status !== 1){
                    resolve({ status: response.status, msg: response.data})
                }
            })
            
            const response = await fetch('/api/user', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            resolve({ status: data.status, msg: data.msg, users: data.users ? data.users : []})

        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },

    async createUser (userData: any): Promise<{ status: number, msg: string, users?: any[] }> {
        return new Promise(async (resolve) => {
            try {
                let accessToken = localStorage.getItem('token');
                authService.checkToken("admin").then((response) => {
                    if(response.status !== 1){
                        resolve({ status: response.status, msg: response.data})
                    }
                })
            const response = await fetch('/api/user', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            data?.status === 20 && (data.msg = strings.users.badRequest);
            data?.status === 41 && (data.msg = strings.users.roleNotFound);
            data?.status === 42 && (data.msg = strings.users.outletNotFound);
            data?.status === 50 && (data.msg = strings.users.notMatchingSlug);
            data?.status === 53 && (data.msg = strings.users.duplicatedUserCode);
            data?.status === 54 && (data.msg = strings.users.outletDisabled);
            data?.status === 60 && (data.msg = strings.users.emailError);

            resolve({ status: data.status, msg: data.msg, users: data.users ? data.users : []})

            return data;
        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },

    async updateUser (userData: any): Promise<{ status: number, msg: string}> {
        return new Promise(async (resolve) => {
            try {
                let accessToken = localStorage.getItem('token');
                authService.checkToken("admin").then((response) => {
                    if(response.status !== 1){
                        resolve({ status: response.status, msg: response.data})
                    }
                })
            const response = await fetch('/api/user', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            response.status === 429 && resolve({ status: response.status, msg: strings.general.tooManyRequests})
            response.status === 401 && resolve({ status: response.status, msg: strings.general.unauthorized})
            response.status === null && resolve({ status: 500, msg: strings.general.criticalError})

            const data = await response.json();

            data?.status === 10 && (data.msg = strings.general.unauthorized);
            data?.status === 11 && (data.msg = strings.general.unauthorized)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            data?.status === 20 && (data.msg = strings.users.badRequest);
            data?.status === 40 && (data.msg = strings.users.notFound);
            data?.status === 54 && (data.msg = strings.users.outletDisabled);

            resolve({ status: data.status, msg: data.msg})
        }
        catch (error:any) {
            resolve({ status: 500, msg: strings.general.criticalError})
        }
    })
    },
}




export default userService;