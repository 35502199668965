import authService from '../services/authService';
import strings from "../common/AppMessages"
const consultService = (code: string): Promise<{ status: number; data: string, winner?: boolean, prize?: string }> => {
    
    return new Promise(async (resolve) => {
      try {
          let accessToken = localStorage.getItem('token');


          if (code.length !== 27) {
              resolve({ status: 400, data: strings.tickets.codeLenghtError, winner: false })
              //throw new Error('El código debe tener 27 dígitos');
          }

          if (localStorage.getItem('role') !== 'user' || localStorage.getItem('token') === '' || localStorage.getItem('token') === null
              || localStorage.getItem('tokenTime') === '' || localStorage.getItem('tokenTime') === null
          ) {
              resolve({ status: 401, data: strings.general.requestNewLogin, winner: false })
          }

          let accessTokenTime = localStorage.getItem('tokenTime');
          let accessRefreshTokenTime = localStorage.getItem('refreshTokenTime');
          if (accessTokenTime !== null && accessRefreshTokenTime !== null) {
              const now = new Date().toISOString()
              if (now > accessTokenTime) {
                  //El token ha expirado

                  if (now > accessRefreshTokenTime) {
                      //El token de refresco ha expirado
                      resolve({ status: 401, data: strings.general.requestNewLogin, winner: false })
                  }
                  else {
                      let accessRefreshToken = localStorage.getItem('refreshToken');
                      accessRefreshToken && await authService.refreshToken(accessRefreshToken).then((v) =>{
                        console.log(v)
                        accessToken = v.token
                      })
                  }
              }
          } else {
              resolve({ status: 401, data: strings.general.requestNewLogin, winner: false })
          }
         
          const response = await fetch(`/api/ticket/validate/${code}`, {
              headers: {
                  'Authorization': `Bearer ${accessToken}`,
              },
          });

          if (response.status === 401) {
            resolve({ status: 401, data: strings.general.unauthorized, winner: false })
            }
          if (response.status === 429) {
              resolve({ status: 429, data: strings.general.tooManyRequests, winner: false })
          }
          if (response.status === null) {
              const error = { code: "500", msg: strings.general.internalCritialError }
              throw new Error(error.msg);
          }
          const data = await response.json();

          if (data.status === 20) {
            resolve({ status: 400, data: strings.general.badRequestOfGeneralData, winner: false })
         }

          if (data.status === 1) {
              resolve({ status: 200, data: strings.tickets.successful , winner: true, prize: data.prize.toString() })
          } else {
              if (data.status === 43) {
                  resolve({ status: 404, data: strings.tickets.notFound, winner: false })
              } else {
                  resolve({ status: 404, data: strings.tickets.alreadyPaid, winner: false })
              }
          }
      } catch (error) {
          console.error('Error:', error);
          throw new Error('Error en el proceso de validación');
      }
  });
}

export default consultService