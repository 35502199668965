import strings from "../common/AppMessages";

const authService = {
    async login(username: string, password: string): Promise<{ status: number, token: string; role: string; msg:string }> {
        try {
            const response = await fetch('/api/oauth/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user: username, password: password }),
            });

            if (response.status === 429) {
                const error = { code: "429", msg: strings.general.tooManyRequests }
                throw new Error(error.msg);
            }
            if (response.status === null) {
                const error = { code: "500", msg: strings.general.internalCritialError }
                throw new Error(error.msg);
            }

            const data = await response.json();

            if (data.status === 1) {
                console.log("Token obtenido con éxito:")
                console.log('API: ', data)

                const role = data.role === "US" ? "user" : data.role === "ADM" ? "admin" : "error"

                localStorage.setItem('token', data.valueToken);
                localStorage.setItem('tokenTime', data.expireToken);
                localStorage.setItem('refreshToken', data.valueRefreshToken);
                localStorage.setItem('refreshTokenTime', data.expireRefreshToken);
                localStorage.setItem('userName', data.name + " " + data.surname);
                localStorage.setItem('role', role);

                //const now = new Date().toISOString()
                

            } else {
                data?.status === 20 && (data.msg = strings.oauth.credentialsFail);
                data?.status === 40 && (data.msg = strings.oauth.usernameFail)
                data?.status === 99 && (data.msg = strings.general.criticalError)
            }
            return data;
        } catch (error) {
            console.error(error);
            throw new Error(error as string);
        }
    },

    async refreshToken(tokenRefresh: string): Promise<{ token: string; }> {
        try {
            const response = await fetch('/api/oauth/refresh', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ valueRefreshToken: tokenRefresh }),
            });

            if (response.status === 429) {
                const error = { code: "429", msg: strings.general.tooManyRequests }
                throw new Error(error.msg);
            }
            if (response.status === null) {
                const error = { code: "500", msg: strings.general.internalCritialError }
                throw new Error(error.msg);
            }

            const data = await response.json();

            if (data.status === 1) {
                console.log("Token obtenido con éxito:")
                console.log('API: ', data)

                const role = data.role === "US" ? "user" : data.role === "ADM" ? "admin" : "error"

                localStorage.setItem('token', data.valueToken);
                localStorage.setItem('tokenTime', data.expireToken);
                localStorage.setItem('refreshToken', data.valueRefreshToken);
                localStorage.setItem('refreshTokenTime', data.expireRefreshToken);
                localStorage.setItem('userName', data.name + " " + data.surname);
                localStorage.setItem('role', role);

                //const now = new Date().toISOString()
                

            } else {
                data?.status === 20 && (data.msg = strings.general.badRequestOfUserData);
                data?.status === 40 && (data.msg = data.msg = strings.oauth.usernameFail)
                data?.status === 45 && (data.msg = data.msg = strings.oauth.refreshTokenFail)
                data?.status === 99 && (data.msg = strings.general.criticalError)
            }


            return {token: data.valueToken};
        } catch (error) {
            console.error(error);
            throw new Error(error as string);
        }
    },

    async sendRecoveryMail(username: string): Promise<{ status: number, msg: string}> {
        try {
            const response = await fetch('/api/password/request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user: username }),
            });

            if (response.status === 429) {
                const error = { code: "429", msg: strings.general.tooManyRequests }
                throw new Error(error.msg);
            }
            if (response.status === null) {
                const error = { code: "500", msg: strings.general.internalCritialError }
                throw new Error(error.msg);
            }

            const data = await response.json();

            data?.status === 20 && (data.msg = data.msg = strings.general.badRequestOfUserData);
            data?.status === 40 && (data.msg = data.msg = strings.oauth.usernameFail)
            data?.status === 60 && (data.msg = strings.general.emailError)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            return data;
        } catch (error) {
            console.error(error);
            throw new Error(error as string);
        }
    },

    async validateToken(token: string): Promise<{ status: number, msg: string }> {
        try {
        const response = await fetch('/api/password/validation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token }),
        }); 

            if (response.status === 429) {
                const error = { code: "429", msg: strings.general.tooManyRequests }
                throw new Error(error.msg);
            }
            if (response.status === null) {
                const error = { code: "500", msg: strings.general.internalCritialError }
                throw new Error(error.msg);
            }

        const data = await response.json();

            data?.status === 20 && (data.msg = strings.general.badRequestOfUserData);
            data?.status === 40 && (data.msg = data.msg = data.msg = strings.oauth.usernameFail)
            data?.status === 46 && (data.msg = strings.general.requestNewPasswordReset)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            return data;
        } catch (error:any) {
            console.error("error");
            return {status: -1, msg:"Error interno crítico"}
        }
    },

    async refreshPassword(password: string, token: string): Promise<{ status: number, msg: string }> {
        try {
            const response = await fetch('/api/password/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: password, token: token }),
            });

            if (response.status === 429) {
                const error = { code: "429", msg: strings.general.tooManyRequests }
                throw new Error(error.msg);
            }
            if (response.status === null) {
                const error = { code: "500", msg: strings.general.internalCritialError }
                throw new Error(error.msg);
            }

            const data = await response.json();

            data?.status === 20 && (data.msg = strings.general.badRequestOfUserData);
            data?.status === 40 && (data.msg = data.msg = data.msg = strings.oauth.usernameFail)
            data?.status === 46 && (data.msg = strings.general.requestNewPasswordReset)
            data?.status === 99 && (data.msg = strings.general.criticalError)

            return data;
        } catch (error) {
            console.error(error);
            throw new Error(error as string);
        }
    },

    async checkToken (role: string): Promise<{ status: number, data: string }> {
        return new Promise(async (resolve) => {
            try{
                let accessToken = localStorage.getItem('token');

                if (localStorage.getItem('role') !== role || localStorage.getItem('token') === '' || localStorage.getItem('token') === null
                    || localStorage.getItem('tokenTime') === '' || localStorage.getItem('tokenTime') === null
                ) {
                    resolve({ status: 401, data: strings.general.sessionExpired })
                }

                let accessTokenTime = localStorage.getItem('tokenTime');
                let accessRefreshTokenTime = localStorage.getItem('refreshTokenTime');
                if (accessTokenTime !== null && accessRefreshTokenTime !== null) {
                    const now = new Date().toISOString()
                    if (now > accessTokenTime) {
                        //El token ha expirado

                        if (now > accessRefreshTokenTime) {
                            //El token de refresco ha expirado
                            resolve({ status: 401, data: strings.general.sessionExpired })
                        }
                        else {
                            let accessRefreshToken = localStorage.getItem('refreshToken');
                            accessRefreshToken && authService.refreshToken(accessRefreshToken).then((v: any) =>{
                                console.log(v.token)
                                accessToken = v.token
                                resolve({ status: 1, data: v.token })
                            })
                        }
                    }
                } else {
                    resolve({ status: 401, data: strings.general.sessionExpired })
                }
            }
            catch (error:any) {
                switch(error.toString()){
                    case 'Error: 401':
                        resolve({  status: 401, data: strings.general.unauthorized });
                        break;
                    case 'Error: 429':
                        resolve({  status: 429, data: strings.general.tooManyRequests });
                        break;
                    default:
                        resolve({  status: 500, data: strings.general.internalCritialError });
                }
            }
        })
    },
};



export default authService;