/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import "./PopUp.scss";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Flip from "@mui/icons-material/Flip";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import LockReset from "@mui/icons-material/LockReset";
import WarningAmber from "@mui/icons-material/WarningAmber";
import Person from "@mui/icons-material/Person";
import PersonOff from "@mui/icons-material/PersonOff";
import Domain from "@mui/icons-material/Domain";
import DomainDisabled from "@mui/icons-material/DomainDisabled";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { CheckCircleOutline } from "@mui/icons-material";
//import { editSalesOutlet } from '../../services/salesOutletsService';
import usersService from "../../services/usersService";
import strings from "../../common/AppMessages";
import salesOutletsService from "../../services/salesOutletsService";
import { useNotification } from "../../context/notification.context";

interface ISalesOutletsData {
  id: string;
  name: string;
  phone: string;
  location: string;
  email: string;
  username: string;
  password: string;
  status: boolean;
  slug: string;
}

interface IONCEUser {
  id: number;
  username: string;
  name: string;
  surnames: string;
  email: string;
  idSalesPoint: number;
  password: string;
  slug: string;
  status: boolean;
  salesPoint: { slug: string };
}

interface PopUpProps {
  openPopup: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setRegisterPayment?: Dispatch<SetStateAction<boolean>>;
  salesOutlets?: ISalesOutletsData[];
  setSalesOutlets?: Dispatch<SetStateAction<ISalesOutletsData[]>>;
  salesOutletDataOnEdit?: ISalesOutletsData | undefined;
  setSalesOutletDataOnEdit?: Dispatch<
    SetStateAction<ISalesOutletsData | undefined>
  >;
  typeStatus?: string;
  status?: number;
  msg?: string;
  data?: any;
  handleOnDisableUser?: (id: number) => void;
  handlePasswordChange?: (id: number) => void;
  renderPreviousScan?: boolean;
  setRenderPreviousScan?: Dispatch<SetStateAction<boolean>>;
  method?: string;
  setMethod?: Dispatch<SetStateAction<string>>;
}

const PopUp: React.FC<PopUpProps> = ({
  openPopup,
  setOpenPopup,
  setRegisterPayment,
  salesOutlets,
  setSalesOutlets,
  salesOutletDataOnEdit,
  typeStatus,
  status,
  msg,
  data,
  handleOnDisableUser,
  handlePasswordChange,
  renderPreviousScan,
  setRenderPreviousScan,
  method,
  setMethod,
}) => {
  const navigate = useNavigate();
  const { getError } = useNotification();
  const [loading, setLoading] = useState(false);
  //const [newPassword, setNewPassword] = useState('');

  const handleDisablingUser = async (id: number) => {
    const storedDataString = localStorage.getItem("userData");
    if (storedDataString !== null) {
      const userData: IONCEUser[] = JSON.parse(storedDataString);
      const index = userData.findIndex((user: any) => {
        return user.id.toString() === id.toString();
      });

      if (index !== -1) {
        const userDataForUpdate = {
          id: userData[index].id,
          name: userData[index].name,
          email: userData[index].email,
          surnames: userData[index].surnames,
          status: userData[index].status === false ? true : false,
        };
        await usersService.updateUser(userDataForUpdate).then((v) => {
          if (v.status === 1) {
            getError(strings.users.updateSuccess, "success");
            userData[index].status =
              userData[index].status === false ? true : false;
            userData.splice(index, 1, userData[index]);
            localStorage.setItem("userData", JSON.stringify(userData));
          }
        });
      }
    }
  };

  const handleClose = async () => {
    if (typeStatus === "back" || typeStatus === "register") {
      if (method !== undefined && setMethod !== undefined) {
        setMethod(method);
        //navigate('/scan');
        if (
          renderPreviousScan !== undefined &&
          setRenderPreviousScan !== undefined
        ) {
          setRenderPreviousScan(!renderPreviousScan);
        }
        setRegisterPayment?.(false);
      } else {
        //navigate('/scan');
        if (
          renderPreviousScan !== undefined &&
          setRenderPreviousScan !== undefined
        ) {
          setRenderPreviousScan(!renderPreviousScan);
        }
        setRegisterPayment?.(false);
      }
    } else if (typeStatus === "disabledSalesOutlet") {
      setLoading(true);
      const updatedSalesOutlet = salesOutletDataOnEdit;
      if (updatedSalesOutlet?.status === true) {
        if (updatedSalesOutlet && updatedSalesOutlet.id) {
          //updatedSalesOutlet.status = false;

          if (salesOutlets) {
            const index = salesOutlets.findIndex(
              (outlet) => outlet.id === updatedSalesOutlet.id
            );

            if (index !== -1) {
              const salesOutletsToUpdate = {
                id: updatedSalesOutlet.id,
                name: updatedSalesOutlet.name,
                location: updatedSalesOutlet.location,
                email: updatedSalesOutlet.email,
                phone: updatedSalesOutlet.phone,
                status: false,
              };
              await salesOutletsService
                .updateSalesOutletData(salesOutletsToUpdate)
                .then((v) => {
                  if (v.status === 1) {
                    updatedSalesOutlet.status = false;
                    const updatedOutlets = [...salesOutlets];
                    updatedOutlets[index] = updatedSalesOutlet;
                    setSalesOutlets?.(updatedOutlets);
                    const listOfUpdatedOutlets = JSON.stringify(updatedOutlets);
                    localStorage.setItem("outletsData", listOfUpdatedOutlets);
                    setLoading(false);
                    //getError(v.msg, "success");
                    const storedDataString = localStorage.getItem("userData");
                    if (storedDataString !== null) {
                      const userData: IONCEUser[] =
                        JSON.parse(storedDataString);
                      const usersToBeDisabled = userData.filter(
                        (user: IONCEUser) => {
                          return (
                            user.idSalesPoint.toString() ===
                            updatedSalesOutlet.id.toString()
                          );
                        }
                      );
                      for (let i = 0; i < usersToBeDisabled.length; i++) {
                        handleDisablingUser(usersToBeDisabled[i].id);
                      }
                      setLoading(true);
                      setLoading(false);
                    }
                  }
                });
            }
          }
        }
      }
    } else if (typeStatus === "enabledSalesOutlet") {
      setLoading(true);
      const updatedSalesOutlet = salesOutletDataOnEdit;
      if (updatedSalesOutlet?.status === false) {
        if (updatedSalesOutlet && updatedSalesOutlet.id) {
          //updatedSalesOutlet.status = false;

          if (salesOutlets) {
            const index = salesOutlets.findIndex(
              (outlet) => outlet.id === updatedSalesOutlet.id
            );

            if (index !== -1) {
              const salesOutletsToUpdate = {
                id: updatedSalesOutlet.id,
                name: updatedSalesOutlet.name,
                location: updatedSalesOutlet.location,
                email: updatedSalesOutlet.email,
                phone: updatedSalesOutlet.phone,
                status: true,
              };
              await salesOutletsService
                .updateSalesOutletData(salesOutletsToUpdate)
                .then((v) => {
                  if (v.status === 1) {
                    updatedSalesOutlet.status = true;
                    const updatedOutlets = [...salesOutlets];
                    updatedOutlets[index] = updatedSalesOutlet;
                    setSalesOutlets?.(updatedOutlets);
                    const listOfUpdatedOutlets = JSON.stringify(updatedOutlets);
                    localStorage.setItem("outletsData", listOfUpdatedOutlets);
                    setLoading(false);
                    //getError(v.msg, "success");
                  }
                });
            }
          }
        }
      }
    } else if (typeStatus === "errorPopup") {
      localStorage.clear();
      navigate("/login");
    }
    setOpenPopup(false);
  };

  return (
    <Dialog
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      fullWidth
      maxWidth={"sm"}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpenPopup(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        {typeStatus === "check" ? (
          <>
            {status === 500 ? (
              <ErrorOutline
                sx={{
                  width: "52px",
                  height: "52px",
                  mt: "-24px",
                  mb: "24px",
                  color: "#f44336",
                }}
              />
            ) : (
              <InfoOutlined
                sx={{
                  width: "52px",
                  height: "52px",
                  mt: "-24px",
                  mb: "24px",
                  color: "#005DC9",
                }}
              />
            )}
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              {msg}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Flip />}
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Escanear otro boleto
            </Button>
          </>
        ) : typeStatus === "register" ? (
          <>
            {status === 500 ? (
              <ErrorOutline
                sx={{
                  width: "52px",
                  height: "52px",
                  mt: "-24px",
                  mb: "24px",
                  color: "#f44336",
                }}
              />
            ) : (
              <CheckCircleOutline
                sx={{
                  width: "52px",
                  height: "52px",
                  mt: "-24px",
                  mb: "24px",
                  color: "#458C48",
                }}
              />
            )}
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              {msg}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Flip />}
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Escanear otro boleto
            </Button>
          </>
        ) : typeStatus === "refresh" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea generar un enlace para el cambio de contraseña de{" "}
              <b>MARTINA PICAZO</b>? <br /> Se enviará un correo a la dirección{" "}
              <b>MARTINA.PICAZO@ONCE.COM</b> con las instrucciones para crear
              una nueva contraseña.
            </Typography>
            <Button
              variant="contained"
              startIcon={<LockReset />}
              sx={{ width: "200px", mb: "10px" }}
              onClick={handleClose}
            >
              Refrescar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "enabledUser" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea deshabilitar el usuario de <b>{data.userFullName}</b>?{" "}
              <br />
            </Typography>
            <Button
              variant="contained"
              startIcon={<PersonOff />}
              sx={{ width: "200px", mb: "10px" }}
              onClick={() => {
                handleClose();
                handleOnDisableUser?.(data.id);
              }}
            >
              Deshabilitar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "disabledUser" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea habilitar el usuario de <b>{data.userFullName}</b>? <br />
            </Typography>
            <Button
              variant="contained"
              startIcon={<Person />}
              sx={{ width: "200px", mb: "10px" }}
              onClick={() => {
                handleClose();
                handleOnDisableUser?.(data.id);
              }}
            >
              Habilitar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "newPassword" ? (
          // <>
          //   <WarningAmber sx={{ width: '52px', height: '52px', mt: '-24px', mb: '24px', color: '#F4A45F' }} />
          //   <Typography sx={{ textAlign: 'center', mb: '32px' }}>
          //     Introduzca la nueva contraseña <br />
          //   </Typography>
          //   <TextField fullWidth type='text' name='pass' label='Contraseña *' sx={{ mb: '24px' }} onChange={(v: any) => setNewPassword(v.target.value)} />
          //   <Button
          //     variant='contained'
          //     startIcon={<Person />}
          //     sx={{ width: '200px', mb: '10px' }}
          //     onClick={() => {
          //       handleClose();
          //       handlePasswordChange?.(newPassword, data.id);
          //     }}>
          //     Actualizar contraseña
          //   </Button>
          //   <Button variant='outlined' sx={{ width: '200px' }} onClick={handleClose}>
          //     Cancelar
          //   </Button>
          // </>
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea reestablecer la contraseña al usuario{" "}
              <b>{data.userFullName}</b>?
            </Typography>
            <Button
              variant="contained"
              startIcon={<Person />}
              sx={{ width: "200px", mb: "10px" }}
              onClick={() => {
                handlePasswordChange?.(data.id);
                getError(
                  "Se ha enviado un correo al destinatario para cambiar la contraseña",
                  "success"
                );
                handleClose();
              }}
            >
              Reestablecer
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "back" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea escanear otro boleto? <br /> Al regresar atrás <b>NO</b> se
              registrará el pago.
            </Typography>
            <Button
              variant="contained"
              sx={{ width: "200px", mb: "10px" }}
              onClick={() => {
                handleClose();
                setRegisterPayment && setRegisterPayment(false);
              }}
            >
              Aceptar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={() => setOpenPopup(false)}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "disabledSalesOutlet" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea deshabilitar el punto de venta{" "}
              <b style={{ textTransform: "uppercase" }}>
                {salesOutletDataOnEdit?.name}
              </b>
              ? <br /> Al deshabilitar el punto de venta se deshabilitarán
              también todos los usuarios asociados a él (
              <b>{data.numberOfAffectedUsers}</b>
              ).
            </Typography>
            <Button
              variant="contained"
              startIcon={
                <>
                  <DomainDisabled />
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#FF0085",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </>
              }
              sx={{ width: "200px", mb: "10px" }}
              onClick={handleClose}
              disabled={loading}
            >
              Deshabilitar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={() => setOpenPopup(false)}
              disabled={loading}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "enabledSalesOutlet" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              ¿Desea habilitar el punto de venta{" "}
              <b style={{ textTransform: "uppercase" }}>
                {salesOutletDataOnEdit?.name}
              </b>
              ?
            </Typography>
            <Button
              variant="contained"
              startIcon={
                <>
                  <Domain />
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#FF0085",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </>
              }
              sx={{ width: "200px", mb: "10px" }}
              onClick={handleClose}
              disabled={loading}
            >
              Habilitar
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={() => setOpenPopup(false)}
              disabled={loading}
            >
              Cancelar
            </Button>
          </>
        ) : typeStatus === "errorPopup" ? (
          <>
            <WarningAmber
              sx={{
                width: "52px",
                height: "52px",
                mt: "-24px",
                mb: "24px",
                color: "#F4A45F",
              }}
            />
            <Typography sx={{ textAlign: "center", mb: "32px" }}>
              {msg}
            </Typography>

            <Button
              variant="outlined"
              sx={{ width: "200px" }}
              onClick={() => {
                handleClose();
              }}
              disabled={loading}
            >
              Cerrar
            </Button>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default PopUp;
