import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@mui/material";
import Block from "@mui/icons-material/Block";

interface UnathorizedProps {}

const Unathorized: React.FC<UnathorizedProps> = () => {
  const navigate = useNavigate();

  const backLogin = () => {
    const role = localStorage.getItem("role");
    if (role !== null) {
      switch (role) {
        case "user":
          navigate("/scan");
          break;
        case "admin":
          navigate("/admin-menu");
          break;
        default:
          localStorage.clear();
          navigate("/");
      }
    } else {
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "-2px", marginLeft: "7px", minHeight: "100vh" }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "555px",
            height: "370px",
            padding: "24px",
          }}
        >
          <Block
            sx={{ width: "52px", height: "52px", mb: "24px", color: "#f44336" }}
          />
          <Typography
            sx={{
              textAlign: "center",
              mb: "20px",
              color: "#444444",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Autorización Requerida (401)
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              color: "#444444",
              mb: "32px",
            }}
          >
            Si el problema persiste, pongase en contacto con el administrador.
          </Typography>
          <Button
            variant="contained"
            sx={{ width: "200px" }}
            onClick={backLogin}
          >
            Volver
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Unathorized;
