import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName");
  let userNameInitials: string = "11";
  if (userName !== null) {
    const firstInitial: string = userName.split(" ")[0][0];
    const secondInitial: string =
      userName.split(" ").length > 1 ? userName.split(" ")[1][0] : "";
    userNameInitials = firstInitial + secondInitial;
  }

  const token = localStorage.getItem("token");
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isSmallScreen = useMediaQuery("(max-width: 440px)");
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const circleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    border: "3px solid #5a5a5a",
    color: "#5a5a5a",
    fontSize: "18px",
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          background: "white",
          boxShadow: " 0px 2px 15px rgba(25, 119, 204, 0.1)",
        }}
      >
        <Toolbar>
          <Container maxWidth="xl">
            <Grid item container sx={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "57%",
                }}
              >
                {screenSize.width < 450 ? (
                  <img
                    alt="Raspa & Gana - Boletos Premiados"
                    src="/raspa-y-gana_favicon.png"
                    style={{ width: "50px", height: "50px" }}
                  />
                ) : (
                  <img
                    alt="Raspa & Gana - Boletos Premiados"
                    src="/raspa-y-gana_logo.png"
                    style={{ width: "200px", height: "32px" }}
                  />
                )}
              </div>
              {token && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "43%",
                  }}
                >
                  <IconButton sx={{ color: "#444444" }} onClick={handleLogout}>
                    <ExitToAppIcon />
                  </IconButton>
                  {isSmallScreen ? (
                    <Avatar sx={circleStyle}>{userNameInitials}</Avatar>
                  ) : (
                    <Typography
                      sx={{
                        color: "#444444",
                        fontWeight: "medium",
                        fontSize: "16x",
                        margin: "0px",
                        textAlign: "center",
                      }}
                    >
                      {userName}
                    </Typography>
                  )}
                </div>
              )}
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
