import React, { useState, useContext } from 'react';
import { Notification } from '../components';
import { AlertColor } from '@mui/material';

interface ContextProps {
  getError: (msg: string, severity: AlertColor | undefined) => void;
}

const NotificationContext = React.createContext<ContextProps | null>(null);

export const NotificationProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  const getError = (msg: string, severity: AlertColor | undefined) => {
    setSeverity(severity);
    setOpen(true);
    setMsg(msg);
  };

  const value = {
    getError,
  };

  return (
    <NotificationContext.Provider value={value}>
      <Notification handleClose={handleClose} open={open} severity={severity} msg={msg} />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) throw new Error('No existe error');
  return context;
};
