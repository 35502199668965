import * as yup from 'yup';

export const NewUsersValidation = yup.object().shape({
  name: yup.string().trim().required('Nombre requerido'),
  surnames: yup.string().trim().required('Apellidos requerido'),
  email: yup.string().trim().required('Email requerido').email('Debe ser un correo válido'),
  username: yup
    .string()
    .trim()
    .required('Nombre de usuario requerido')
    .test('is-unique', 'El nombre de usuario ya existe', function (value) {
      const fetchedUserData = localStorage.getItem('userData');
      const fetchedUserDataJSON = fetchedUserData !== null ? JSON.parse(fetchedUserData) : [];
      const similarities = fetchedUserDataJSON.filter((user: any) => user.username === value);
      return similarities.length === 0;
    }),
  password: yup
    .string()
    .trim()
    .required('Contraseña requerida')
    .test('passwords-structure', 'Las contraseñas deben contener una mayúscula, una minúscula y un número', function (value) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
      return regex.test(value);
    })
    .test('passwords-lenght', 'Las contraseñas deben ser de al menos 8 caracteres', function (value) {
      const regex = /.{8,}/;
      return regex.test(value);
    }),
});

export const EditUsersValidation = yup.object().shape({
  name: yup.string().trim().required('Nombre requerido'),
  surnames: yup.string().trim().required('Apellidos requerida'),
  email: yup.string().trim().required('Email requerido').email('Debe ser un correo válido'),
});
