/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RouterLayout } from './common/RouterLayout';
import Login from './pages/Login';
import { PasswordRecovery } from './pages/PasswordRecovery';
import { ScanViewHtml5 } from './pages/ScanViewHtml5';
import { Management } from './pages/Management';
import Unauthorized from './pages/Unauthorized';
import { Navigate } from 'react-router-dom';

export const AppRouter: React.FC<{}> = () => {
  const role = localStorage.getItem('role');

  const getRole = () => {
    return role;
  };

  return (
      <Routes>
          <Route path='/' element={<RouterLayout />}>
              <Route
                  path='/'
                  element={
                      getRole() === null || getRole() === undefined || getRole() === '' ? (
                          <Login />
                      ) : getRole() === 'user' ? (
                          <Navigate to='/scan' replace={true} />
                      ) : getRole() === 'admin' ? (
                          <Navigate to='/admin-menu' replace={true} />
                      ) : (
                          <Unauthorized />
                      )
                  }
              />
              <Route path='/login' element={<Login />} />
              <Route path='/recuperar-contrasena' element={<PasswordRecovery />} />
              <Route path='/admin-menu' element={<Management />} />
              <Route path='/scan' element={<ScanViewHtml5 />} />
          </Route>
      </Routes>
  );
};
