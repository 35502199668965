/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import { Users } from "../components/Users/Users";
import { SalesOutlets } from "../components/SalesOutlets/SalesOutlets";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Unauthorized from "./Unauthorized";
import usersService from "../services/usersService";
import salesOutletsService from "../services/salesOutletsService";

interface ManagementProps {}

export const Management: React.FC<ManagementProps> = () => {
  const [value, setValue] = useState("1");
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfOutlets, setNumberOfOutlets] = useState(0);
  const role = localStorage.getItem("role");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const parseUserData = (storedDataString: any) => {
    const userData: any[] = JSON.parse(storedDataString);
    userData;
  };

  const getAllUserData = () => {
    let storedDataString = localStorage.getItem("userData");

    if (storedDataString === null) {
      usersService.getUserData().then((v) => {
        if (v.status === 1) {
          storedDataString = JSON.stringify(v.users);
          localStorage.setItem("userData", JSON.stringify(v.users));
          parseUserData(storedDataString);
          setNumberOfUsers(
            storedDataString ? JSON.parse(storedDataString).length : 0
          );
        }
      });
    }
    setNumberOfUsers(
      storedDataString ? JSON.parse(storedDataString).length : 0
    );
  };

  const getAllSalesOutletsData = async (reload?: boolean) => {
    let storedDataString;
    if (!reload) {
      storedDataString = localStorage.getItem("outletsData");
      const storedData =
        storedDataString !== null ? JSON.parse(storedDataString) : [];
      setNumberOfOutlets(storedData.length);
    }

    if (storedDataString === null || storedDataString === undefined) {
      await salesOutletsService.getSalesOutletsData().then((v) => {
        if (v.status === 1) {
          storedDataString = JSON.stringify(v.salesPoint);
          localStorage.setItem("outletsData", JSON.stringify(v.salesPoint));
          setNumberOfOutlets(
            storedDataString ? JSON.parse(storedDataString).length : 0
          );
        }
      });
    }
    setNumberOfOutlets(
      storedDataString ? JSON.parse(storedDataString).length : 0
    );
  };

  useEffect(() => {
    getAllUserData();
    getAllSalesOutletsData();
  }, []);

  return (
    <>
      {role === "admin" ? (
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            marginTop: "82px",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                textColor="secondary"
                indicatorColor="secondary"
                onChange={handleChange}
              >
                <Tab
                  label={"Puntos de ventas (" + numberOfOutlets + ")"}
                  value="1"
                />
                <Tab label={"Usuarios (" + numberOfUsers + ")"} value="2" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <SalesOutlets setNumberOfOutlets={setNumberOfOutlets} />
            </TabPanel>
            <TabPanel value="2">
              <Users setNumberOfUsers={setNumberOfUsers} />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
