/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./UserForm.scss";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNotification } from "../../context/notification.context";
import {
  EditUsersValidation,
  NewUsersValidation,
} from "../../utils/usersValidation";
import usersService from "../../services/usersService";
// import jwt from 'jsonwebtoken'
import strings from "../../common/AppMessages";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";

interface UserFormProps {
  openUserForm: boolean;
  setOpenUserForm: Dispatch<SetStateAction<boolean>>;
  users: IONCEUser[];
  editingUser: IONCEUser | undefined;
  setEditingUser: Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  refreshList: boolean;
  setRefreshList: Dispatch<SetStateAction<boolean>>;
}

interface IONCEUser {
  id: number;
  username: string;
  name: string;
  surnames: string;
  email: string;
  idSalesPoint: number;
  salesPoint: { slug: string };
  password: string;
  slug: string;
  status: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
  openUserForm,
  setOpenUserForm,
  users,
  editingUser,
  setEditingUser,
  loading,
  setRefreshList,
  refreshList,
}) => {
  const [salesOutlets, setSalesOutlets] = useState<any[]>([]);
  //const [selectedVendingPoint, setSelectedVendingPoint] = useState<string>();
  const { getError } = useNotification();
  const [helperTextEmail, setHelperTextEmail] = useState(false);
  const [helperTextPass, setHelperTextPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const emptyNewUser: IONCEUser = {
    name: "",
    surnames: "",
    email: "",
    status: true,
    username: "",
    idSalesPoint: -1,
    password: handleGeneratePassword(),
    slug: "",
    id: -1,
    salesPoint: { slug: "" },
  };

  const [newUser, setNewUser] = useState<IONCEUser>(emptyNewUser);

  const handleClose = () => {
    setOpenUserForm(false);
    setNewUser(emptyNewUser);
    setEditingUser(undefined);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleSaveToLocalStorage();
  };

  function handleGeneratePassword() {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";

    //Se añade una mayúscula, minúscula y número aleatorios para cumplir el mínimo
    password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
    password += getRandomChar("abcdefghijklmnopqrstuvwxyz");
    password += getRandomChar("0123456789");

    //Se rellena el resto de la contra
    while (password.length < 16) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  }

  function getRandomChar(character: string) {
    const randomIndex = Math.floor(Math.random() * character.length);
    return character[randomIndex];
  }

  function sortOutletsByName(outlets: any[]) {
    return outlets.slice().sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  useEffect(() => {
    if (editingUser) {
      //   editingUser.username = editingUser.username.split('.')[1];
      setNewUser(editingUser);
    }
    const listOfOutletsOptionsJSON = localStorage.getItem(
      "listOfOutletsOptions"
    );
    setSalesOutlets(
      listOfOutletsOptionsJSON !== null
        ? sortOutletsByName(JSON.parse(listOfOutletsOptionsJSON))
        : []
    );
  }, []);

  useEffect(() => {
    if (editingUser !== undefined) {
      setNewUser(editingUser);
      const vendingPoint: { value: number; label: string } = salesOutlets?.find(
        (option: { value: number; label: string }) => {
          return (
            option.value.toString() === editingUser?.idSalesPoint.toString()
          );
        }
      );
      setNewUser((editingUser: any) => ({
        ...editingUser,
        salesOutlets: vendingPoint.value,
      }));
      //setSelectedVendingPoint(editingUser?.salesOutlet);
    }
  }, [editingUser]);

  const updateUserData = (id: number, data: any) => {
    const updateData = users.map((user) => {
      if (user.id === id) {
        return { ...user, ...data };
      }
      return user;
    });
    return updateData;
  };

  const checkEmptyProperties = (data: IONCEUser): string => {
    const emptyProperties: string[] = [];
    Object.entries(data).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value === "") ||
        value === undefined ||
        value === null
      ) {
        emptyProperties.push(key);
      }
    });
    return emptyProperties.join(", ");
  };

  const saveToLocalStorage = async (data: IONCEUser) => {
    // const token = jwt.sign(data, "secreto")
    // localStorage.setItem('token_bien_perruno', JSON.stringify(token));
    console.log(users);
    try {
      const missingData = checkEmptyProperties(data);
      if (missingData.length > 0) {
        getError("Debe rellenar todos los campos requeridos", "error");
      } else {
        if (!editingUser) {
          //User is being created:

          await NewUsersValidation.validate(data);
          const userDataForCreation = {
            username: data.slug + "." + data.username,
            password: data.password,
            name: data.name,
            surnames: data.surnames,
            email: data.email,
            status: true,
            idRole: 1,
            idSalesPoint: data.idSalesPoint,
          };
          await usersService.createUser(userDataForCreation).then((v) => {
            if (v.status === 1) {
              if (v.users !== undefined) {
                data.id = v.users[0].id;
                data.status = v.users[0].status;
                data.username = data.slug + "." + data.username;
                data.salesPoint.slug = data.slug;
              }
              users.push(data);
              localStorage.setItem("userData", JSON.stringify(users));
              getError(strings.users.creationSuccess, "success");
              setEditingUser(undefined);
              setRefreshList(!refreshList);
              handleClose();
            } else {
              if (v.status === 401) {
                localStorage.clear();
                navigate("/");
              } else {
                getError(v.msg, "error");
              }
            }
          });
        } else {
          //User is being updated:

          await EditUsersValidation.validate(data);
          const userDataForUpdate = {
            id: data.id,
            name: data.name,
            email: data.email,
            surnames: data.surnames,
            status: true,
          };
          await usersService.updateUser(userDataForUpdate).then((v) => {
            if (v.status === 1) {
              users = updateUserData(editingUser.id, data);
              localStorage.setItem("userData", JSON.stringify(users));
              getError(strings.users.updateSuccess, "success");
              setEditingUser(undefined);
              setRefreshList(!refreshList);
              handleClose();
            } else {
              if (v.status === 401) {
                localStorage.clear();
                navigate("/");
              } else {
                getError(v.msg, "error");
              }
            }
          });
        }
      }
    } catch (e: any) {
      getError(e.message, "error");
    }
  };

  const handleSaveToLocalStorage = () => {
    if (newUser) {
      saveToLocalStorage(newUser);
    } else {
      getError("No hay datos que actualizar", "error");
    }
  };

  const convertToSlug = (text: string): string => {
    const slug = text.toLowerCase().replace(/\s/g, "");

    const normalizedSlug = slug
      .replace(/[áàäâ]/g, "a")
      .replace(/[éèëê]/g, "e")
      .replace(/[íìïî]/g, "i")
      .replace(/[óòöô]/g, "o")
      .replace(/[úùüû]/g, "u")
      .replace(/ñ/g, "n");

    const cleanSlug = normalizedSlug.replace(/[^a-z0-9\s-]/g, "");

    return cleanSlug;
  };

  const compoundUserRegex = /^([a-zA-Z0-9]+[.])?[a-zA-Z0-9]*$/;

  return (
    <Dialog open={openUserForm} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <>
          <Typography
            sx={{
              mb: "32px",
              color: "#444444",
              fontWeight: "medium",
              fontSize: "20px",
            }}
          >
            {editingUser ? "Editar usuario" : "Crear usuario"}
          </Typography>
          {/* <Typography sx={{ mb: '32px', color: '#444444', fontWeight: 'medium', fontSize: '20px' }}>Editar usuario</Typography> */}
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              select
              name="salesOutlet"
              label="Punto de venta *"
              sx={{ mb: "24px" }}
              value={Number(newUser?.idSalesPoint)}
              onChange={(v: any) => {
                const outlets = localStorage.getItem("outletsData");
                setNewUser((newUser: any) => ({
                  ...newUser,
                  idSalesPoint: v.target.value,
                  slug: convertToSlug(
                    outlets !== null
                      ? JSON.parse(outlets).find(
                          (item: any) =>
                            item.id?.toString() === v.target.value?.toString()
                        )?.slug
                      : null
                  ),
                  salesPoint: {
                    slug: convertToSlug(
                      outlets !== null
                        ? JSON.parse(outlets).find(
                            (item: any) =>
                              item.id?.toString() === v.target.value?.toString()
                          )?.slug
                        : null
                    ),
                  },
                }));
                //setSelectedVendingPoint(v.target.value);
              }}
              disabled={editingUser !== undefined}
              inputProps={{
                maxLength: 250,
              }}
            >
              {salesOutlets?.map((option: { value: string; label: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              type="text"
              name="username"
              label="Usuario *"
              sx={{ mb: "24px" }}
              value={newUser && newUser.username.split(".")[1]}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginRight: "0px" }}
                  >
                    {newUser?.salesPoint?.slug + "."}
                  </InputAdornment>
                ),
              }}
              onChange={(v: any) => {
                // const userNameToInput = decimalRegex.test(v.target.value)
                //   ? (newUser?.salesOutlet ? convertToSlug(newUser?.salesOutlet) : '') + '.' + v.target.value
                //   : newUser?.username;
                //const userNameToInput = decimalRegex.test(v.target.value) ? v.target.value  : newUser?.username;
                // Si el username viene como outlet.user
                const validatedUsername = compoundUserRegex.test(v.target.value)
                  ? v.target.value
                  : newUser?.username;
                //const userNameToInput: string = validatedUsername.split('.').length === 1 ? convertToSlug(newUser?.slug) + '.' + validatedUsername : validatedUsername;
                //v.target.value?.split(".").length === 2 && decimalRegex.test(v.target.value) ? v.target.value : newUser?.username;
                // Si el username viene como user
                // Si el username u outlet viene con un . de más

                setNewUser((newUser: any) => ({
                  ...newUser,
                  username: validatedUsername.toLocaleLowerCase(),
                }));
              }}
              disabled={editingUser !== undefined}
              inputProps={{
                maxLength: 40,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="name"
              label="Nombre *"
              sx={{ mb: "24px" }}
              value={newUser?.name}
              onChange={(v: any) =>
                setNewUser((newUser: any) => ({
                  ...newUser,
                  name: v.target.value,
                }))
              }
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="surname"
              label="Apellidos *"
              sx={{ mb: "24px" }}
              value={newUser?.surnames}
              onChange={(v: any) =>
                setNewUser((newUser: any) => ({
                  ...newUser,
                  surnames: v.target.value,
                }))
              }
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            <TextField
              fullWidth
              type="text"
              name="email"
              label="Email *"
              sx={{ mb: "24px" }}
              helperText={
                helperTextEmail &&
                "El correo debe tener el siguiente formato: usuario@proveedor.com "
              }
              onFocus={() => setHelperTextEmail(true)}
              onBlur={() => setHelperTextEmail(false)}
              value={newUser?.email}
              onChange={(v: any) =>
                setNewUser((newUser: any) => ({
                  ...newUser,
                  email: v.target.value,
                }))
              }
              disabled={loading}
              inputProps={{
                maxLength: 250,
              }}
            />
            {!editingUser && (
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                style={{ display: editingUser ? "none" : "block" }}
                name="password"
                value={newUser?.password}
                helperText={
                  helperTextPass &&
                  "La contraseña debe contener al menos 8 caracteres, una letra mayúscula y un carácter numérico."
                }
                onFocus={() => setHelperTextPass(true)}
                onBlur={() => setHelperTextPass(false)}
                label="Contraseña *"
                sx={{ mb: "32px" }}
                onChange={(v: any) =>
                  setNewUser((newUser: any) => ({
                    ...newUser,
                    password: v.target.value,
                  }))
                }
                disabled={false}
                inputProps={{
                  maxLength: 250,
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      tabIndex={-1}
                      defaultValue={"true"}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            )}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mb: "9px" }}
              startIcon={
                <>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#FF0085",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </>
              }
              onClick={() => {
                handleSubmit;
              }}
            >
              {editingUser ? "Guardar" : "Añadir"}
            </Button>
            {/* <Button fullWidth type='submit' variant='contained' color='primary' sx={{ mb: '9px' }}>
              Guardar
            </Button> */}
          </Box>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;
