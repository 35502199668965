/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNotification } from "../context/notification.context";
import {
  LoginPasswordRecoveryValidation,
  LoginValidation,
} from "../utils/loginValidation";
import authService from "../services/authService";
import "./Login.scss";
import PopUp from "../components/PopUp/PopUp";

interface ILoginData {
  username: string;
  password: string;
  loginDataInputted: string;
}

interface ILoginPasswordRecoveryData {
  email: string;
  usernameRecovery: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { getError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState<ILoginData>({
    username: "",
    password: "",
    loginDataInputted: "",
  });
  const [loginPasswordRecoveryData, setLoginPasswordRecoveryData] =
    useState<ILoginPasswordRecoveryData>({
      email: "",
      usernameRecovery: "",
    });
  const [forgotPassword, setForgotPassword] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const dataLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const dataLoginPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPasswordRecoveryData({
      ...loginPasswordRecoveryData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!forgotPassword) {
        const logginDataConst = {
          ...loginData,
          loginDataInputted: loginData.username + loginData.password,
        };
        await LoginValidation.validate(logginDataConst);
        await authService
          .login(logginDataConst.username, logginDataConst.password)
          .then((v) => {
            if (v?.status === 1) {
              setLoading(true);
              const role = localStorage.getItem("role");
              if (role === "user") {
                navigate("/scan");
              } else if (role === "admin") {
                navigate("/admin-menu");
                //localStorage.clear();'
              }
              setLoading(false);
            }
            if (v?.status === 20 || v?.status === 40) {
              getError(v?.msg, "error");
              setLoading(false);
            } else {
              setOpen(true);
              setMsg(v.msg);
              setLoading(false);
            }
            setLoading(false);
          });
      } else if (forgotPassword) {
        await LoginPasswordRecoveryValidation.validate(
          loginPasswordRecoveryData
        );
        await authService
          .sendRecoveryMail(loginPasswordRecoveryData.usernameRecovery)
          .then((v) => {
            if (v?.status === 1) {
              getError(
                "En breves minutos recibirá un correo para restablecer su contraseña, recuerde revisar la carpeta SPAM por si fuese redirigido allí",
                "success"
              );
              setLoading(false);
            } else {
              setOpen(true);
              setMsg(v.msg);
              setLoading(false);
            }
          });
        setLoading(false);
        navigate(`/login`);
      }
    } catch (e: any) {
      setLoading(false);
      getError(e.message, "error");
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid
          className="loginCard"
          item
          sx={{ width: "555px", height: "370px", padding: "24px" }}
        >
          {!forgotPassword ? (
            <>
              <Typography sx={{ mb: "40px" }} variant="h5" color="secondary">
                Iniciar Sesión
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  type="text"
                  name="username"
                  label="Usuario *"
                  sx={{ mb: "24px" }}
                  onChange={dataLogin}
                />
                <TextField
                  fullWidth
                  type="password"
                  name="password"
                  label="Contraseña *"
                  sx={{ mb: "32px" }}
                  onChange={dataLogin}
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mb: "9px" }}
                  startIcon={
                    <>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "#FF0085",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </>
                  }
                  disabled={loading}
                >
                  Acceder
                </Button>
              </Box>
              <Button
                fullWidth
                variant="text"
                color="primary"
                sx={{ textAlign: "center" }}
                onClick={() => setForgotPassword(true)}
                disabled={loading}
              >
                ¿Ha olvidado su contraseña?
              </Button>
            </>
          ) : (
            <>
              <Typography sx={{ mb: "40px" }} variant="h5" color="secondary">
                Recuperar Contraseña
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  type="text"
                  name="usernameRecovery"
                  label="Usuario *"
                  sx={{ marginBottom: "111px" }}
                  onChange={dataLoginPassword}
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mb: "10px" }}
                  startIcon={
                    <>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "#FF0085",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </>
                  }
                  disabled={loading}
                >
                  Enviar email
                </Button>
              </Box>
              <Button
                fullWidth
                variant="text"
                color="primary"
                sx={{ textAlign: "center" }}
                onClick={() => setForgotPassword(!forgotPassword)}
                disabled={loading}
              >
                Volver a iniciar sesión
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <PopUp
        openPopup={open}
        setOpenPopup={setOpen}
        typeStatus={"errorPopup"}
        msg={msg}
      />
    </Container>
  );
};

export default Login;
