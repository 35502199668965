import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import DomainAdd from "@mui/icons-material/DomainAdd";
import DownloadIcon from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import Domain from "@mui/icons-material/Domain";
import DomainDisabled from "@mui/icons-material/DomainDisabled";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import SalesOutletForm from "../SalesOutletForm/SalesOutletForm";
import PopUp from "../PopUp/PopUp";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import salesOutletsService from "../../services/salesOutletsService";
import { useNotification } from "../../context/notification.context";

interface SalesOutletsProps {
  setNumberOfOutlets: Dispatch<SetStateAction<number>>;
}

interface ISalesOutletsData {
  id: string;
  name: string;
  phone: string;
  location: string;
  email: string;
  username: string;
  password: string;
  status: boolean;
  slug: string;
}

export const SalesOutlets: React.FC<SalesOutletsProps> = ({
  setNumberOfOutlets,
}) => {
  const [openSalesOutletForm, setOpenSalesOutletForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [salesOutletOnEdit, setSalesOutletOnEdit] = useState<
    ISalesOutletsData | undefined
  >(undefined);
  const [searchSalesOutlets] = useState("");
  const [typeStatus, setTypeStatus] = useState("");
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = React.useState(1);
  const [rows, setRows] = useState<any[]>([]);

  const isSmallScreen = useMediaQuery("(max-width: 976px)");

  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [sortingProperty, setSortingProperty] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [rowsData, setRowsData] = useState<any>();
  const [formattedListData, setFormattedListData] = useState<any>();
  const [totalPagesData, setTotalPagesData] = useState<any>();

  const itemsPerPage = 10;

  const [salesOutlets, setSalesOutlets] = useState<ISalesOutletsData[]>([]);

  const [refreshOutlets, setRefreshOutlets] = useState<boolean>(false);
  const { getError } = useNotification();

  const onNew = () => {
    setSalesOutletOnEdit(undefined);
    setOpenSalesOutletForm(true);
  };

  const onEdit = (salesOutletData: ISalesOutletsData) => {
    setSalesOutletOnEdit(salesOutletData);
    setOpenSalesOutletForm(true);
  };

  const onDisabled = (salesOutletData: ISalesOutletsData) => {
    setTypeStatus(
      salesOutletData?.status ? "disabledSalesOutlet" : "enabledSalesOutlet"
    );
    const numberOfAffectedUsers = getNumberofUsersOfOutlet(salesOutletData.id);
    setSalesOutletOnEdit(salesOutletData);
    setData({ numberOfAffectedUsers: numberOfAffectedUsers });
    setOpen(true);
  };

  const convertToCSV = (objArray: any) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let csv = "";

    // Agregar encabezados
    csv += "\uFEFF" + Object.keys(array[0]).join(";") + "\n"; // Agregar BOM

    // Agregar filas
    array.forEach((item: any) => {
      csv += Object.values(item).join(";") + "\n";
    });

    return csv;
  };

  const handleExport = () => {
    const csvString = convertToCSV(salesOutlets);

    // Crear un objeto Blob que representa el archivo CSV
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Crear un enlace de descarga
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "puntosdeventa.csv";

    // Hacer clic en el enlace para iniciar la descarga
    downloadLink.click();
  };

  function sortOutletsByID(outlets: ISalesOutletsData[]) {
    return outlets.slice().sort((a, b) => {
      const idA = Number(a.id);
      const idB = Number(b.id);

      if (idA < idB) {
        return -1;
      }
      if (idA > idB) {
        return 1;
      }

      return 0;
    });
  }

  const getAllSalesOutletsData = async (reload?: boolean) => {
    let storedDataString;
    if (!reload) {
      storedDataString = localStorage.getItem("outletsData");
      const storedData =
        storedDataString !== null ? JSON.parse(storedDataString) : [];
      setNumberOfOutlets(storedData.length);
    }

    function createData(
      id: string,
      name: string,
      slug: string,
      location: string,
      phone: string,
      email: string,
      status: boolean
    ) {
      return { id, name, slug, location, phone, email, status };
    }

    function sortOutletsByID(outlets: ISalesOutletsData[]) {
      return outlets.slice().sort((a, b) => {
        const idA = Number(a.id);
        const idB = Number(b.id);

        if (idA < idB) {
          return -1;
        }
        if (idA > idB) {
          return 1;
        }

        return 0;
      });
    }

    function parseSalesOutletData(storedDataString: any) {
      const outletData: ISalesOutletsData[] = JSON.parse(storedDataString);
      const formattedList: any[] = [];
      const listOfOutletsOptions: any[] = [];
      //setUsers(JSON.parse(storedDataString));
      outletData.map((outlet) => {
        formattedList.push(
          createData(
            outlet.id ?? "",
            outlet.name,
            outlet.slug,
            outlet.location,
            outlet.phone,
            outlet.email,
            outlet.status
          )
        );
        listOfOutletsOptions.push({
          value: outlet.id,
          label: outlet.name,
        });
      });

      setSalesOutlets(formattedList);
      setRowsData(sortOutletsByID(formattedList));
      setFormattedListData(formattedList);
      setTotalPagesData(Math.ceil(formattedList.length / itemsPerPage));

      localStorage.setItem(
        "listOfOutletsOptions",
        JSON.stringify(listOfOutletsOptions)
      );

      return { rowsData, formattedListData, totalPagesData };
    }

    if (storedDataString === null || storedDataString === undefined) {
      await salesOutletsService.getSalesOutletsData().then((v) => {
        if (v.status === 1) {
          storedDataString = JSON.stringify(v.salesPoint);
          localStorage.setItem("outletsData", JSON.stringify(v.salesPoint));
        } else {
          if (v.status === 401) {
            localStorage.clear();
          } else {
            getError(v.msg, "error");
          }
        }
        //TODO tratamiento errores
      });
    }
    parseSalesOutletData(storedDataString);
  };

  useEffect(() => {
    setLoading(true);
    getAllSalesOutletsData(true).then(() => {
      setLoading(false);
    });
  }, [refreshOutlets]);

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return rows.slice(startIndex, endIndex);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  useEffect(() => {
    if (searchSalesOutlets === "") {
      setLoading(true);
      getAllSalesOutletsData();
      setLoading(false);
    }
  }, [searchSalesOutlets]);

  const paginatedData = getPaginatedData();

  const getNumberofUsersOfOutlet = (outletID: string) => {
    let storedDataString = localStorage.getItem("userData");
    if (storedDataString !== null) {
      const usersDataFromJSON: any[] = JSON.parse(storedDataString);
      return usersDataFromJSON.filter((user) => user.idSalesPoint === outletID)
        .length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const filteredOutlets = sortOutletsByID(salesOutlets).filter((row) =>
      row.name.toLowerCase().includes(searchSalesOutlets.toLowerCase())
    );
    setRows(filteredOutlets);
    setTotalPages(Math.ceil(filteredOutlets.length / itemsPerPage));
  }, [salesOutlets, searchSalesOutlets]);

  useEffect(() => {
    getAllSalesOutletsData();
  }, []);

  function sortOutletsByColumn(
    saleOutlets: ISalesOutletsData[],
    column: string,
    mode: boolean
  ) {
    return saleOutlets.slice().sort((a, b) => {
      let elementA = a[column];
      let elementB = b[column];
      if (column === "salesPoint") {
        elementA = elementA?.nameOutlet?.toString();
        elementB = elementB?.nameOutlet?.toString();
      }
      // mode === false => Ordena alfabéticamente / de menor a mayor
      // mode === true => Ordena alfabéticamente al revés / de mayor a menor
      if (typeof elementA === "string" && typeof elementB === "string") {
        return mode === false
          ? elementA.localeCompare(elementB)
          : elementB.localeCompare(elementA);
      } else if (typeof elementA === "number" && typeof elementB === "number") {
        return mode === false ? elementA - elementB : elementB - elementA;
      } else {
        // Handle other types as needed
        return 0;
      }
    });
  }

  function filterEnabled(status: string) {
    if (status === "null") {
      setIsFiltered(false);
      getAllSalesOutletsData();
    } else {
      setIsFiltered(true);
      const filteredData = salesOutlets.filter((outlet) => {
        const statusToFilter = status === "Habilitado" ? true : false;
        return outlet?.status === statusToFilter;
      });
      setRows(filteredData);
      //setFetchedUserData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
    }
  }

  const handleColumnSort = (internalName: string) => {
    setLoading(true);
    if (sortingProperty === internalName) {
      const sortedRows = sortOutletsByColumn(rows, internalName, false);
      setRows(sortedRows);
      //setFetchedUserData(sortedRows);
      setSortingProperty(internalName + "2");
    } else {
      const sortedRows = sortOutletsByColumn(rows, internalName, true);
      setRows(sortedRows);
      //setFetchedUserData(sortedRows);
      setSortingProperty(internalName);
    }
    setLoading(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const cropLongText = (text: string, length?: number) => {
    if (text.length > (length ? length : 20)) {
      return text.slice(0, length ? length - 3 : 17) + "...";
    } else {
      return text;
    }
  };
  return (
    <>
      <Grid
        className="codeInput"
        container
        sx={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <TextField
          label="Buscar punto de venta"
          size={"small"}
          variant="outlined"
          sx={{ width: isSmallScreen ? "75%" : "74%" }}
          onChange={(v) => {
            setRows(
              salesOutlets.filter(
                (row) =>
                  row?.name
                    ?.toLowerCase()
                    .includes(v.target.value.toLowerCase()) ||
                  row?.location
                    ?.toLowerCase()
                    .includes(v.target.value.toLowerCase()) ||
                  row?.slug
                    ?.toLowerCase()
                    .includes(v.target.value.toLowerCase()) ||
                  row?.phone
                    ?.toLowerCase()
                    .includes(v.target.value.toLowerCase()) ||
                  row?.email
                    ?.toLowerCase()
                    .includes(v.target.value.toLowerCase())
              )
            );
            setTotalPages(
              Math.ceil(
                salesOutlets.filter(
                  (row) =>
                    row?.name
                      ?.toLowerCase()
                      .includes(v.target.value.toLowerCase()) ||
                    row?.location
                      ?.toLowerCase()
                      .includes(v.target.value.toLowerCase()) ||
                    row?.slug
                      ?.toLowerCase()
                      .includes(v.target.value.toLowerCase()) ||
                    row?.phone
                      ?.toLowerCase()
                      .includes(v.target.value.toLowerCase()) ||
                    row?.email
                      ?.toLowerCase()
                      .includes(v.target.value.toLowerCase())
                ).length / itemsPerPage
              )
            );
          }}
        />
        <Button
          variant="contained"
          startIcon={<DomainAdd />}
          onClick={onNew}
          sx={{
            ml: "5px",
            width: "25%%",
            minWidth: !isSmallScreen ? "100px" : "0px",
            "& .MuiButton-startIcon": {
              marginLeft: isSmallScreen ? "auto" : "5px",
              marginRight: isSmallScreen ? "auto" : "5px",
            },
          }}
        >
          {!isSmallScreen && "Crear punto de venta"}
        </Button>

        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleExport}
          sx={{
            ml: "5px",
            width: "25%%",
            minWidth: !isSmallScreen ? "100px" : "0px",
            "& .MuiButton-startIcon": {
              marginLeft: isSmallScreen ? "auto" : "5px",
              marginRight: isSmallScreen ? "auto" : "5px",
            },
          }}
        >
          {!isSmallScreen && "Descargar"}
        </Button>
      </Grid>
      {loading ? (
        <>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12">
              <CircularProgress />
            </div>
          </div>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  ID
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("id");
                    }}
                  >
                    {sortingProperty?.startsWith("id") ? (
                      sortingProperty === "id" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Nombre
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("name");
                    }}
                  >
                    {sortingProperty?.startsWith("name") ? (
                      sortingProperty === "name" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Slug
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("slug");
                    }}
                  >
                    {sortingProperty?.startsWith("slug") ? (
                      sortingProperty === "slug" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Dirección
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("location");
                    }}
                  >
                    {sortingProperty?.startsWith("location") ? (
                      sortingProperty === "location" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">Teléfono</TableCell>
                <TableCell align="center">
                  Email
                  <Button
                    style={{ minWidth: "0px" }}
                    onClick={() => {
                      handleColumnSort("email");
                    }}
                  >
                    {sortingProperty?.startsWith("email") ? (
                      sortingProperty === "email" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  Estado
                  <Button
                    style={{
                      minWidth: "0px",
                      color: isFiltered === false ? "#a1a1a1" : "#ff752c",
                    }}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <FilterAltIcon />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={() => filterEnabled("Habilitado")}>
                      Habilitado
                    </MenuItem>
                    <MenuItem onClick={() => filterEnabled("Deshabilitado")}>
                      Deshabilitado
                    </MenuItem>
                    <MenuItem onClick={() => filterEnabled("null")}>
                      Borrar filtros
                    </MenuItem>
                  </Menu>
                </TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData
                .filter(
                  (row) =>
                    row.name
                      .toLowerCase()
                      .includes(searchSalesOutlets.toLowerCase()) ||
                    row.location
                      .toLowerCase()
                      .includes(searchSalesOutlets.toLowerCase()) ||
                    row.phone
                      .toLowerCase()
                      .includes(searchSalesOutlets.toLowerCase()) ||
                    row.email
                      .toLowerCase()
                      .includes(searchSalesOutlets.toLowerCase())
                )
                .map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.slug}</TableCell>
                    <TableCell align="center" title={row.location}>
                      {cropLongText(row.location, 40)}
                    </TableCell>
                    <TableCell align="center" title={row.phone}>
                      {cropLongText(row.phone)}
                    </TableCell>
                    <TableCell align="center" title={row.email}>
                      {cropLongText(row.email)}
                    </TableCell>
                    <TableCell align="center">
                      {row.status === true ? (
                        <CheckCircle
                          titleAccess="Habilitado"
                          sx={{ color: "#458C48" }}
                        />
                      ) : (
                        <Cancel
                          titleAccess="Deshabilitado"
                          sx={{ color: "#f44336" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        title="Editar"
                        onClick={() => onEdit(row)}
                        disabled={!row.status}
                      >
                        <Edit />
                      </IconButton>
                      {row.status === true ? (
                        <IconButton
                          title="Deshabilitar punto de venta"
                          onClick={() => onDisabled(row)}
                        >
                          <DomainDisabled />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="Habilitar punto de venta"
                          onClick={() => onDisabled(row)}
                        >
                          <Domain />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={totalPages}
            onChange={handlePageChange}
            style={{
              position: "absolute",
              margin: "20px",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </TableContainer>
      )}
      <SalesOutletForm
        openSalesOutletForm={openSalesOutletForm}
        setOpenSalesOutletForm={setOpenSalesOutletForm}
        salesOutlets={salesOutlets}
        setSalesOutlets={setSalesOutlets}
        onEdit={salesOutletOnEdit}
        getAllSalesOutletsData={getAllSalesOutletsData}
        refreshOutlets={refreshOutlets}
        setRefreshOutlets={setRefreshOutlets}
      />
      <PopUp
        openPopup={open}
        setOpenPopup={setOpen}
        typeStatus={typeStatus}
        salesOutlets={salesOutlets}
        setSalesOutlets={setSalesOutlets}
        salesOutletDataOnEdit={salesOutletOnEdit}
        setSalesOutletDataOnEdit={setSalesOutletOnEdit}
        data={{ numberOfAffectedUsers: data?.numberOfAffectedUsers }}
      />
    </>
  );
};
